import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { DayWidthContext } from "../contexts";
import moment from "moment";
import { type CalendarEvent } from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { GradientText } from "../../../../../ux/glow-pill";

const CalendarHeaderContainer = styled("div", ({ $theme }) => ({
  // minHeight: 40,
  background: $theme.background3,
  display: "flex",
  flexDirection: "row",
  zIndex: 1,
  boxShadow: "0px 20px 20px rgba(0,0,0,0.25)",
}));

const DayHeaderContainer = styled<"div", { day: number; dayWidth: number }>(
  "div",
  ({ $theme, day, dayWidth }) => ({
    borderLeftWidth: day !== 1 ? 0.5 : 0,
    borderRightWidth: day !== 0 ? 0.5 : 0,
    width: dayWidth - 1 - 7.5 - 7.5,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingTop: 10,
    borderColor: $theme.midground,
    borderStyle: "solid",
    // height: '100%',
    paddingLeft: 7.5,
    paddingRight: 7.5,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    paddingBottom: 5,
    color: $theme.foreground,
  }),
);

export const AllDayEventTileContainer = styled("div", {
  backgroundColor: "#2196f388",
  fontSize: 12,
  padding: 5,
  lineHeight: "12px",
  borderRadius: 2.5,
  cursor: "pointer",
  userSelect: "none",
  whiteSpace: "pre-wrap",
  textOverflow: "break-word",
});

const AllDayEventTile = React.memo(function AllDayEventTile(props: {
  event: CalendarEvent;
  onClick: () => void;
}): JSX.Element {
  return (
    <AllDayEventTileContainer onClick={props.onClick}>
      {props.event.title}
    </AllDayEventTileContainer>
  );
});

export const DayHeader = React.memo(function DayColumn(props: {
  date: Date;
  day: number;
  events: CalendarEvent[];
  setSelectedCalendarEventUUID: (uuid: string | null) => void;
}): JSX.Element {
  const dayWidth = React.useContext(DayWidthContext);
  const dayEvents = React.useMemo(
    () =>
      props.events.filter(
        (event) =>
          moment(event.timing._computed.start).format("YYYYMMDD") ===
          moment(props.date).format("YYYYMMDD"),
      ),
    [props.events, props.date],
  );

  const Wrapper = moment().format("YYYYMMDD") === moment(props.date).format("YYYYMMDD") ? GradientText : React.Fragment;

  return (
    <DayHeaderContainer day={props.day} dayWidth={dayWidth}>
      <div style={{ width: "min-content" }}>
        <Wrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 5,
              paddingBottom: 2.5,
              width: "max-content"
            }}
          >
            <div
              style={{
                fontSize: 17.5,
                lineHeight: "15px",
                textAlign: "left",
                fontWeight: "bold",
                fontFamily: "hiyllo"
              }}
            >
              {moment(props.date).days(props.day).format("ddd")}
            </div>

            <div
              style={{
                fontSize: 15,
                lineHeight: "1em",
                textAlign: "left",
                // fontWeight: "bold",
                fontFamily: "hiyllo"
              }}
            >
              {moment(props.date).days(props.day).format("MMM Do")}
            </div>
          </div>
        </Wrapper>
      </div>
      {dayEvents.map((event) => (
        <AllDayEventTile
          key={event.uuid}
          event={event}
          onClick={() => props.setSelectedCalendarEventUUID(event.uuid)}
        />
      ))}
    </DayHeaderContainer>
  );
});

export const CalendarHeader = React.memo(function CalendarHeader({
  startOfWeek,
  events,
  setSelectedCalendarEventUUID,
}: {
  startOfWeek: Date;
  events: CalendarEvent[];
  setSelectedCalendarEventUUID: (uuid: string | null) => void;
}): JSX.Element {
  const allDayEvents = React.useMemo(
    () => events.filter((event) => event.timing.allDay === true),
    [events],
  );

  return (
    <CalendarHeaderContainer>
      <div style={{ width: 60 }} />
      <DayHeader
        day={1}
        date={startOfWeek}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
      <DayHeader
        day={2}
        date={moment(startOfWeek).add(1, "day").toDate()}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
      <DayHeader
        day={3}
        date={moment(startOfWeek).add(2, "day").toDate()}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
      <DayHeader
        day={4}
        date={moment(startOfWeek).add(3, "day").toDate()}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
      <DayHeader
        day={5}
        date={moment(startOfWeek).add(4, "day").toDate()}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
      <DayHeader
        day={6}
        date={moment(startOfWeek).add(5, "day").toDate()}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
      <DayHeader
        day={0}
        date={moment(startOfWeek).add(6, "day").toDate()}
        events={allDayEvents}
        setSelectedCalendarEventUUID={setSelectedCalendarEventUUID}
      />
    </CalendarHeaderContainer>
  );
});
