import { ReactEditor } from "slate-react";
import { BooleanMarkEnum, toggleBooleanMark } from "../marks/boolean-marks";
import React from "react";
import { selectNearestWord } from "../actions/select-nearest-word";
import { selectWholeLine } from "../actions/select-whole-line";

export function useOnKeyDown(
  editor: ReactEditor,
  options: {
    setMetaKeyHeld: (value: boolean) => void,
    setAltKeyHeld: (value: boolean) => void,
    setCommandPaletteOpen: (value: boolean) => void,
    toggleFindAndReplace: () => void,
  }
) {
  const { setMetaKeyHeld, setAltKeyHeld, setCommandPaletteOpen, toggleFindAndReplace } = options;

  return React.useCallback((event: React.KeyboardEvent) => {
    const isMac = /Mac/.test(navigator.platform);
    setMetaKeyHeld(event.metaKey);
    setAltKeyHeld(event.altKey);

    if (event.key === "Escape") {
      setCommandPaletteOpen(false);
    }

    if (isMac ? event.metaKey : event.ctrlKey) {
      if (event.key === "b") {
        event.preventDefault();
        toggleBooleanMark(editor, BooleanMarkEnum.bold);
      }
      else if (event.key === "i") {
        event.preventDefault();
        toggleBooleanMark(editor, BooleanMarkEnum.italic);
      }
      else if (event.key === "u") {
        event.preventDefault();
        toggleBooleanMark(editor, BooleanMarkEnum.underline);
      }
      else if (event.key === "f") {
        event.preventDefault();
        toggleFindAndReplace();
      }
    }
    if (event.altKey) { // Option / Alt
      if (event.shiftKey) { // Shift
        if (event.code === "KeyW") { // Option + Shift + W select nearest word
          event.preventDefault();
          selectNearestWord(editor);
        }
        if (event.code === "KeyL") { // Option + Shift + W select line
          event.preventDefault();
          selectWholeLine(editor);
        }
      }
      if (event.code === "Enter") {
        event.preventDefault();
        setCommandPaletteOpen(true);
      }
    }
  }, [editor, setMetaKeyHeld, setAltKeyHeld, setCommandPaletteOpen]);
}