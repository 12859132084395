import React from "react";
import { ThemeProvider, ThemeType } from "@hiyllo/ux/theme";
import { HiylloTokyoThemeDark } from '@hiyllo/ux/tokyo-theme';

export const HiylloTokyoThemeLight: ThemeType = {
  buttonForeground: "white",
  buttonBackground: "linear-gradient(to right, rgb(254 2 254), rgb(254 143 1))",
  buttonBackgroundRed: "red",
  background1: "#fefdf8",
  background2: "#e1e1d8",
  background3: "#f4f4ee",
  background4: "#f7f6ee",
  midground: "#ebebe2",
  midground1: "#ebebe2",
  midground1Highlight: "#e5e5e5",
  midground2: "#b2b3af",
  foreground: "#121212",
  foregroundInactive: "#656570",
  surfaceShadow: "rgba(0, 0, 0, 0.25) 0px 0px 20px 0px",
  colorScheme: "dark",
  colorSubtleAccent: "blue",
  colorWarning: "#ff9800",
  dock: {
    background: "#0373b9",
    buttonBGInactive: "#257ebf",
    buttonBGActive: "rgba(255, 255, 255, 0.25)",
    buttonFGInactive: "#fefdf8",
    buttonFGActive: "#eeeeee",
  },
  sidebar: {
    background: "#0798f2",
    foregroundActive: "white",
    foregroundInactive: "rgba(255, 255, 255, 0.75)",
    linkBGActive: "rgba(255, 255, 255, 0.25)",
  }
};

export const UXThemeProvider = React.memo(function UXThemeProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  return (
    <ThemeProvider theme={window.localStorage.lightMode === "1" ? HiylloTokyoThemeLight : HiylloTokyoThemeDark}>{props.children}</ThemeProvider>
  );
});
