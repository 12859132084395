import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { Highlight, PrismTheme } from "prism-react-renderer";

export const LANGUAGE_OPTIONS = [
  {
    label: "JavaScript",
    value: "js-extras",
  },
  {
    label: "TypeScript (TSX)",
    value: "tsx",
  },
  {
    label: "JSON",
    value: "json",
  },
  {
    label: "Markup",
    value: "markup",
  }
] as const;

const CodeBlockContainer = styled("div", ({ $theme }) => ({ borderRadius: 5, padding: 10, background: $theme.background3 }));

export function usePrismTheme(): PrismTheme {
  const $theme = useTheme();

  return React.useMemo(() => ({
    "plain": {
      "color": $theme.foreground,
      "backgroundColor": $theme.background3
    },
    "styles": [
      {
        "types": [
          "prolog"
        ],
        "style": {
          "color": "rgb(0, 0, 128)"
        }
      },
      {
        "types": [
          "comment"
        ],
        "style": {
          "color": "rgb(106, 153, 85)"
        }
      },
      {
        "types": [
          "builtin",
          "changed",
          "keyword",
          "interpolation-punctuation"
        ],
        "style": {
          "color": $theme.colorSubtleAccent
        }
      },
      {
        "types": [
          "number",
          "inserted"
        ],
        "style": {
          "color": "#4caf50"
        }
      },
      {
        "types": [
          "constant"
        ],
        "style": {
          "color": "#673ab7"
        }
      },
      {
        "types": [
          "attr-name",
          "variable"
        ],
        "style": {
          "color": "rgb(156, 220, 254)"
        }
      },
      {
        "types": [
          "deleted",
          "string",
          "attr-value",
          "template-punctuation"
        ],
        "style": {
          "color": "rgb(206, 145, 120)"
        }
      },
      {
        "types": [
          "selector"
        ],
        "style": {
          "color": "rgb(215, 186, 125)"
        }
      },
      {
        "types": [
          "tag"
        ],
        "style": {
          "color": "rgb(78, 201, 176)"
        }
      },
      {
        "types": [
          "tag"
        ],
        "languages": [
          "markup"
        ],
        "style": {
          "color": "rgb(86, 156, 214)"
        }
      },
      {
        "types": [
          "punctuation",
          "operator"
        ],
        "style": {
          "color": "rgb(212, 212, 212)"
        }
      },
      {
        "types": [
          "punctuation"
        ],
        "languages": [
          "markup"
        ],
        "style": {
          "color": "#808080"
        }
      },
      {
        "types": [
          "function"
        ],
        "style": {
          "color": "rgb(220, 220, 170)"
        }
      },
      {
        "types": [
          "class-name"
        ],
        "style": {
          "color": "rgb(78, 201, 176)"
        }
      },
      {
        "types": [
          "char"
        ],
        "style": {
          "color": "rgb(209, 105, 105)"
        }
      }
    ]
  }), [$theme.background3, $theme.colorSubtleAccent]);
}

export const ReadOnlyCodeBlock = React.memo(function CodeBlock(props: {
  language: typeof LANGUAGE_OPTIONS[number]["value"] | null;
  content: string;
}): JSX.Element {
  const prismTheme = usePrismTheme();
  const foundLanguage = LANGUAGE_OPTIONS.find(l => props.content.startsWith(l.value));
  const autoDetectedLanguage = foundLanguage ? foundLanguage.value : null;
  const code = foundLanguage ? props.content.slice(foundLanguage.value.length) : props.content;

  return (
    <CodeBlockContainer>
      <Highlight
        theme={prismTheme}
        code={code.trim()}
        language={autoDetectedLanguage ?? props.language ?? "markup"}
      >
        {({ style, tokens, getLineProps, getTokenProps }) => (
          <pre style={{ ...style, margin: 0 }}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </CodeBlockContainer>
  );
});