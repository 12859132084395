/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  projectUUID: string;
  archiveAllTasks: boolean;
};
export type ResponseType = void;
export const Endpoint = "tasks/archive-project";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 2,
  per: 750,
};export const paramsSchema = {"type":"object","properties":{"projectUUID":{"type":"string"},"archiveAllTasks":{"type":"boolean"}},"required":["archiveAllTasks","projectUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}