import React from "react";
import { EditorCtx } from "../editor-v2";
import { ReactEditor } from "slate-react";
import { SlateElementWithType } from "../types";
import { Node, Transforms } from "slate";

export function useChangeNodeData() {
  const editor = React.useContext(EditorCtx);

  if (editor == null) throw new Error("editor is null");

  return React.useCallback((element: SlateElementWithType, data: Record<string, any>) => {
    const newProperties = {
      data
    };

    Transforms.setNodes(editor, newProperties as Partial<Node>, {
      at: ReactEditor.findPath(editor, element)
    });
  }, [editor]);
}