import React from "react";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { ProjectSelect, SizingPill, SprintSelect, TaskColors, TaskIcons, TaskPillsRow, useCreateTask, useDefaults, useGetMySprints, usePriorities, usePriorityDisplay, useStatuses } from "@hiyllo/omni-tasks";
import { useSelf } from "@hiyllo/omni-continuity";
import { RouterContext, useDeriveURL, useNavigate, usePath } from "@hiyllo/omni-router";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type MoopsyError } from "@moopsyjs/core";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { Button } from "@hiyllo/ux/button";
import { Structure } from "@hiyllo/ux/structure";
// import * as GetDraftTaskRecommendedProjectBP from "../../../blueprints/tasks//get-draft-task-recommended-project";

import { StatusPill, AssigneePill, DueDatePill } from "@hiyllo/omni-tasks";
import {
  type TaskSizingType,
  type TaskDueDateType,
} from "../../../types/tasks/task-item";
import { Features } from "../../../types/navigation/features";
import { isCommandKeyPressed } from "../../../platform/web/is-command-key-pressed";
import { ErrorText } from "../../../ux/error";
import { useConfig } from "../../../platform/config/config-context";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { TaskCardQueryWrapper } from "../components/task-card";
import { NymblPill } from "../../../ux/nymbl-pill";
import { Typography } from "@hiyllo/ux/typography";
import { KeyCombinationHint } from "../../stuff/documents/v2/editor-v2";
import { HotkeyEvent, KeyCombination, useHotkey } from "@hiyllo/ux/hotkeys";
import { TaskPriorityV3, TaskStatusV3 } from "../../../types/tasks/tasks-organization";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

const Header = styled("div", {
  fontSize: 24,
  lineHeight: "1.25em",
});

const Spacer = styled("div", { height: 15 });


const Label = styled("div", {
  fontSize: 12,
  marginBottom: 5,
});

const PriorityRowSelectRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const PriorityRowPill = styled<"div", { color: string; active: boolean }>(
  "div",
  ({ color, active }) => ({
    padding: 5,
    paddingLeft: 5,
    paddingRight: 7.5,
    color: active ? "white" : color,
    backgroundColor: active ? color : "transparent",
    borderColor: color,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 20,
    fontSize: 12,
    gap: 2.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  }),
);

const PriorityRowSelect = React.memo(function PriorityRowSelect(props: {
  priority: TaskPriorityV3;
  onChangePriority: (priority: TaskPriorityV3) => void;
  projectUUID: string;
}): JSX.Element {
  const priorities = usePriorities(props.projectUUID);
  const statuses = useStatuses(props.projectUUID);
  console.log(">>>> statuses", statuses);

  return (
    <PriorityRowSelectRow>
      {priorities.map((p) => (
        <PriorityRowPill
          key={p.uuid}
          color={TaskColors[p.color]}
          active={props.priority.uuid === p.uuid}
          onClick={() => props.onChangePriority(p)}
        >
          <FontAwesomeIcon icon={TaskIcons[p.icon]} fixedWidth />
          {p.label}
        </PriorityRowPill>
      ))}
    </PriorityRowSelectRow>
  );
});

interface PropsType {
  projectUUID: string | null;
  sprintUUID: string | null;
  statusUUID: string | null;
  assigneeId: string | null;
  title?: string | null;
  description?: string | null;
  noRedirect?: boolean;
  onTaskCreated?: ((taskUUID: string) => void) | null;
}

const createTaskCombination: KeyCombination = ["meta", "Enter"];

const CreateNewTaskViewInner = React.memo(function CreateNewTaskViewInner(
  props: PropsType,
): JSX.Element {
  const [projectUUID, setProjectUUID] = React.useState<string | null>(
    props.projectUUID,
  );
  const [sprintUUID, setSprintUUID] = React.useState<string | null>(
    props.sprintUUID,
  );
  const self = useSelf();
  const titleInputControl = useInputControl({});
  const descriptionInputControl = useInputControl({});
  const [priority, setPriority] = React.useState<TaskPriorityV3 | null>(null);
  const [status, setStatus] = React.useState<TaskStatusV3 | null>(null);
  const [assigneeUserId, setAssignee] = React.useState<string | null>(
    props.assigneeId ?? self.userId,
  );
  const [dueDate, setDueDate] = React.useState<TaskDueDateType | null>(null);
  const [sizing, setSizing] = React.useState<TaskSizingType | null>(null);
  const [createAnotherTask, setCreateAnotherTask] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const sprintsQuery = useGetMySprints(null);
  const [success, setSuccess] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    setSuccess(false);
  }, [priority, dueDate, sizing, projectUUID, sprintUUID, status, assigneeUserId]);

  React.useEffect(() => {
    setProjectUUID(props.projectUUID);
  }, [props.projectUUID]);

  const [guessedProjectUUID, setGuessedProjectUUID] = React.useState<string | null>(null);

  // const guessProjectUUIDMutation = seamlessClient.useMutation<GetDraftTaskRecommendedProjectBP.Plug>(GetDraftTaskRecommendedProjectBP);

  // const debounce = useDebounce(500, "overwrite");
  // React.useEffect(() => {
  //   if (IS_BETA_ENV) {
  //     if (titleInputControl.value.length > 0) {
  //       debounce.debounce(() => {
  //         const params = {
  //           title: titleInputControl.valueRef.current,
  //           description: descriptionInputControl.valueRef.current,
  //         };
  //         console.log("Guessing from", params);
  //         void guessProjectUUIDMutation.call(params).then((res) => {
  //           console.log("Guessed", res);
  //           setGuessedProjectUUID(res.projectUUID);
  //         });
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [titleInputControl.value, descriptionInputControl.value]);

  const showDialog = useShowDialog();
  const path = usePath();
  const deriveURL = useDeriveURL();
  const { createTask: doCreateTask, isLoading } = useCreateTask({
    onSimilarTaskWarning: async (similarTask) => {
      const value = await new Promise<boolean>((resolve) => {
        showDialog({
          title: "Similar Task",
          message: `A similar task (${similarTask.title}) already exists. Do you want to create another task?`,
          onCancel: () => resolve(false),
          extras: (
            <RouterContext.Provider
              value={{
                path,
                navigate,
                deriveURL,
              }}
            >
              <div style={{ boxShadow: "rgba(0,0,0,0.25) 0px 0px 20px 20px", borderRadius: 15, marginTop: 10, marginBottom: 10 }}>
                <TaskCardQueryWrapper
                  taskUUID={similarTask.uuid}
                />
              </div>
            </RouterContext.Provider>
          ),
          buttons: [
            {
              type: 'primary',
              label: "Continue Anyway",
              onClick: () => {
                resolve(true);
              },
            },
            {
              type: 'secondary',
              label: "Cancel",
              onClick: () => {
                resolve(false);
              },
            },
          ]
        });
      });

      return value;
    },
    onErrorMessage: setErrorMessage,
  });

  const createTaskRunningRef = React.useRef(false);
  const createTask = React.useCallback(
    (createAnother?: boolean) => {
      if (createTaskRunningRef.current) {
        return;
      }

      const finalProjectUUID = projectUUID ?? guessedProjectUUID;

      if (finalProjectUUID == null) {
        setErrorMessage("Please select a project");
        return;
      }

      if (status == null) {
        setErrorMessage("Please select a status");
        return;
      }

      if (priority == null) {
        setErrorMessage("Please select a priority");
        return;
      }

      const title = titleInputControl.value;
      const description = descriptionInputControl.value;

      void doCreateTask({
        title,
        description,
        status,
        priority,
        dueDate,
        sizing,
        assigneeUserId,
        projectUUID: finalProjectUUID,
        parentUUID: null,
        sprintUUID,
      })
        .then(async (res) => {
          createTaskRunningRef.current = false;

          if (props.onTaskCreated != null && res?.taskUUID != null) {
            return props.onTaskCreated(res.taskUUID);
          }

          if (res != null) {
            titleInputControl.update("");
            descriptionInputControl.update("");

            if (
              !createAnotherTask &&
              createAnother !== true &&
              props.noRedirect !== true
            ) {
              if (sprintUUID != null) {
                const sprint = sprintsQuery.data?.sprints.find(
                  (s) => s.uuid === sprintUUID,
                );
                const teamUUID = sprint?.teamUUID;

                if (teamUUID != null) {
                  navigate({
                    feature: Features.tasks,
                    params: {
                      view: "sprint-planning",
                      sprintUUID,
                      teamUUID,
                    },
                  });

                  return;
                }
              }

              navigate({
                feature: Features.tasks,
                params: {
                  view: "project",
                  projectUUID: finalProjectUUID,
                },
              });
            } else {
              setSuccess(true);
            }
          }
        })
        .catch((err: MoopsyError) => {
          createTaskRunningRef.current = false;
          setErrorMessage(err.description);
        });
    },
    [assigneeUserId, createAnotherTask, descriptionInputControl, doCreateTask, dueDate, guessedProjectUUID, navigate, priority, projectUUID, props, sizing, sprintUUID, sprintsQuery.data?.sprints, status, titleInputControl],
  );

  const onCreateTask = React.useCallback((evt: HotkeyEvent) => {
    createTask(evt.shiftKey);
  }, [createTask]);

  useHotkey(createTaskCombination, onCreateTask);

  const onKeyDown = React.useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
      setSuccess(false);
      if (isCommandKeyPressed(evt) && evt.key === "Enter") {
        evt.stopPropagation();
        createTask(evt.shiftKey);
      }
    },
    [createTask],
  );

  const config = useConfig();
  const isSolo = useIsSolo();

  const defaults = useDefaults(projectUUID ?? "");
  const statuses = useStatuses(projectUUID ?? "");

  React.useEffect(() => {
    if (defaults != null && projectUUID != null) {
      if (priority == null) {
        setPriority(defaults.priority);
      }

      if (status == null) {
        if (props.statusUUID != null) {
          setStatus(statuses.find((s) => s.uuid === props.statusUUID) ?? null);
        }
        else {
          setStatus(defaults.status);
        }
      }
    }
  }, [defaults, priority, projectUUID, props.statusUUID, status, statuses]);

  return (
    <>
      <Card $color="background3">
        <Header>Create Task</Header>
        <Spacer />
        {/* @ts-expect-error --- TaskPillsRow */}
        <TaskPillsRow>
          <DueDatePill dueDate={dueDate} onChangeDueDate={setDueDate} />
          {!isSolo ? (
            <>
              <SizingPill sizing={sizing} onChangeSizing={setSizing} />
              <AssigneePill
                assignee={assigneeUserId}
                onChangeAssignee={setAssignee}
              />
            </>
          ) : null}
        </TaskPillsRow>
        <Spacer />
        <div>
          <Input
            onKeyDown={onKeyDown}
            type="text"
            autoFocus
            placeholder="What needs to be done?"
            fullWidth
            multiline
            containerStyle={{
              padding: 5,
              fontSize: 17.5,
            }}
            {...titleInputControl.inputProps}
          />
        </div>
        <Spacer />
        <div>
          <Input
            onKeyDown={onKeyDown}
            type="text"
            placeholder="Optionally, add a description..."
            fullWidth
            inputStyle={{
              paddingTop: 5,
              paddingLeft: 2.5,
              paddingRight: 2.5,
              minHeight: "5em",
            }}
            containerStyle={{
              fontSize: 17.5,
            }}
            multiline
            {...descriptionInputControl.inputProps}
          />
          <div style={{ height: 10 }} />
        </div>
        {/* @ts-expect-error --- TaskPillsRow */}
        <TaskPillsRow style={{ alignItems: "flex-start" }}>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              flexBasis: "calc(50% - 10px)",
            }}
            className="ProjectSelectContainer"
          >
            <Structure.LColumn10>
              <div>
                <Typography.Label style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  Project
                </Typography.Label>
                <ProjectSelect
                  projectUUID={projectUUID ?? guessedProjectUUID}
                  setProjectUUID={setProjectUUID}
                  disallowNullSelection={isSolo}
                />
              </div>
              <AnimateChangeInHeight>
                {projectUUID == null && guessedProjectUUID != null ?
                  <NymblPill>
                    I selected a project based on your new task
                  </NymblPill>
                  : null}
              </AnimateChangeInHeight>
            </Structure.LColumn10>
          </div>
          {!isSolo ? (
            <>
              <div
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                  flexBasis: "calc(50% - 10px)",
                }}
                className="SprintSelectContainer"
              >
                <Label>Sprint</Label>
                <SprintSelect
                  value={sprintUUID ?? null}
                  onChangeValue={(option) => setSprintUUID(option as string)}
                />
              </div>
            </>
          ) : null}
        </TaskPillsRow>
        <AnimateChangeInHeight>
          {projectUUID != null && priority != null && status != null ?
            // @ts-expect-error --- TaskPillsRow
            <TaskPillsRow>
              <PriorityRowSelect
                priority={priority}
                onChangePriority={setPriority}
                projectUUID={projectUUID}
              />
              <StatusPill
                status={status}
                onChangeStatus={setStatus}
                projectUUID={projectUUID}
              />
            </TaskPillsRow> : <div />}
        </AnimateChangeInHeight>
        <Spacer />
        {config.restricted !== true ? (
          <Structure.Row>
            <Button
              label={
                <>
                  Create Task
                  <KeyCombinationHint
                    combination={createTaskCombination}
                  />
                </>
              }
              isLoading={isLoading}
              onClick={() => createTask()}
              success={success}
              successMessage="Created!"
            />
            <CheckboxInput
              value={createAnotherTask}
              onChange={setCreateAnotherTask}
              label={"Create Another Task"}
            />
          </Structure.Row>
        ) : (
          <div>Please fix your billing issues to create new tasks</div>
        )}
        <AnimateChangeInHeight>
          {errorMessage != null ? <div style={{ height: 15 }} /> : null}
          <ErrorText message={errorMessage} />
        </AnimateChangeInHeight>
      </Card>
    </>
  );
});

export const CreateNewTaskView = React.memo(function CreateNewTaskView(
  props: PropsType,
): JSX.Element {
  return (
    <TabDetails label="Create Task" icon={faPlus}>
      <CreateNewTaskViewInner {...props} />
    </TabDetails>
  );
});
