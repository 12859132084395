import React from "react";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { faArrowUpRightFromSquare, faSquareArrowUpRight, type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { ContextMenuContainer, ContextMenuItem, useContextMenu } from "@hiyllo/ux/context-menu";
import { EDataType } from "../../../types/navigation/edata";
import { useDeriveURL } from "@hiyllo/omni-router/main";
import { openWindow } from "../../../platform/open-window";
import { useNavigate } from "@hiyllo/omni-continuity/main";

const BUTTON_HEIGHT = 32.5;

const LSButtonElem = styled<"div", { isActive: boolean }>(
  "div",
  ({ $theme, isActive }) => ({
    paddingLeft: 15,
    paddingRight: 20,
    height: BUTTON_HEIGHT,
    color: isActive ? ($theme.sidebar?.foregroundActive ?? $theme.foreground) : ($theme.sidebar?.foregroundInactive ?? $theme.foregroundInactive),
    fontFamily: "hiyllo",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      background: $theme.sidebar?.linkBGActive ?? $theme.background3,
    },
  }),
);

export const LSButton = (props: {
  isActive: boolean;
  label: string | JSX.Element;
  icon: { fa: IconDefinition } | { element: JSX.Element };
  skeleton?: boolean;
  onContextMenu?: () => void;
  contextButtonRef?: React.RefObject<HTMLDivElement>;
  indent?: boolean;
  hasNextSibling?: boolean;
} & (
    { onClick: () => void; } | { link: EDataType; }
  )): JSX.Element => {
  const theme = useTheme();
  const colorFG = props.isActive ? theme.foreground : theme.foregroundInactive;
  const cxMenu = useContextMenu({ positionMode: "fixed" });
  const deriveURL = useDeriveURL();
  const navigate = useNavigate();

  return (
    <>
      <LSButtonElem isActive={props.isActive} onClick={'onClick' in props ? props.onClick : () => {
        navigate(props.link);
      }} onContextMenu={(evt: React.MouseEvent<HTMLDivElement>) => {
        if ('link' in props) {
          evt.preventDefault();
          cxMenu.open(evt as any as React.MouseEvent<HTMLButtonElement>);
        }
      }}>
        {props.indent === true ?
          <div style={{ position: "relative", height: BUTTON_HEIGHT, width: BUTTON_HEIGHT, paddingLeft: 0, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <div style={{ position: "absolute", height: props.hasNextSibling ? "100%" : "50%", top: 0, left: "calc(50% - 0.5px)", width: 1, background: theme.midground1 }} />
            <div style={{ position: "absolute", height: 1, left: "calc(50% - 0.5px)", top: "calc(50% - 0.5px)", width: "50%", background: theme.midground1 }} />
          </div>
          : null}
        <div
          style={{
            width: BUTTON_HEIGHT,
            height: BUTTON_HEIGHT,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: props.skeleton ? colorFG : "transparent",
          }}
        >
          {"element" in props.icon ? (
            props.icon.element
          ) : (
            <FontAwesomeIcon icon={props.icon.fa} />
          )}
        </div>
        <div
          style={{
            marginLeft: 5,
            fontSize: 15,
            letterSpacing: 1,
            background: props.skeleton ? colorFG : "transparent",
          }}
        >
          {props.label}
        </div>
        {props.onContextMenu != null ? (
          <>
            <div style={{ flexGrow: 1, width: 0 }} />
            <div
              ref={props.contextButtonRef}
              style={{ cursor: "pointer", width: 10, flexShrink: 0 }}
              onClick={(evt) => {
                evt.stopPropagation();
                props.onContextMenu?.();
              }}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          </>
        ) : null}
      </LSButtonElem>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={faArrowUpRightFromSquare}
            label="Open in New Tab"
            onClick={() => {
              if ("link" in props) {
                openWindow(deriveURL(props.link));
                cxMenu.close();
              }
            }}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
    </>
  );
};
