import { faSquareUser as faSquareUserHollow, faLink, faMicrophone, faTimes, faVideoCamera, faVideoSlash, faMicrophoneSlash, faDisplaySlash, faSquareUser, faHammerBrush } from "@fortawesome/pro-light-svg-icons";
import { Button, ButtonVariant } from "@hiyllo/ux/button";
import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { ScreenshareIcon } from "@hiyllo/icons";
import { MeetingCanvas, MeetingCanvasDimensionsCtx } from "../providers/meeting-canvas";
import { useTracks } from "../lib/use-tracks";
import { TrackTile } from "./components/track-tile";
import { getTrackReferenceId, isTrackReference, setupDisconnectButton } from "@livekit/components-core";
import { ParticipantContextIfNeeded, TrackRefContext, useRoomContext, useTrackToggle } from "@livekit/components-react";
import { AudioPresets, Track, VideoPresets, ConnectionState } from "livekit-client";
import { Electron } from "../../../platform/electron";
import { useStartScreenShare } from "../v2/hooks/start-screen-share";
import { BackgroundBlur } from "@livekit/track-processors";
import { ContinuityMeetingContext } from "../../../main/meeting-provider";
import { getRootURL } from "../../../platform/environment/get-root-url";
import { useSelfMaybe } from "@hiyllo/omni-continuity/main";
import { MeetingEventProvider, StartedContext, useMeetingEvent } from "./meeting-event-provider";
import { MeetingTimer } from "../ui/meeting-timer";
import { Modal } from "@hiyllo/ux/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DisconnectedIndicator } from "../ui/disconnected-indicator";

const TrackCountContext = React.createContext<number>(0);

const OuterContainer = styled("div", ({ $theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: $theme.background1,
  display: "flex",
  flexDirection: "column",
}));

const TilesContainer = styled("div", ({
  height: 0,
  flexGrow: 1,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const BottomBar = styled("div", ({ $theme }) => ({
  height: 65,
  width: "calc(100% - 32.5px)",
  paddingLeft: 15,
  paddingRight: 15,
  backgroundColor: $theme.background2,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ButtonsRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const MeetingTimerWrapper = React.memo(
  function MeetingTimerWrapper(): JSX.Element | null {
    const startDate = React.useContext(StartedContext);
    const event = useMeetingEvent();

    if (event != null) {
      return (
        <MeetingTimer
          startDate={event.timing._computed.start}
          endDate={event.timing._computed.end}
        />
      );
    }

    return <MeetingTimer startDate={startDate ?? new Date()} />;
  },
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TitleBar = React.memo(function TitleBar(props: {
  type: "guest";
} | {
  type: "call";
} | {
  type: "event";
}): JSX.Element {
  const { current } = React.useContext(ContinuityMeetingContext);
  const isSpectator = React.useContext(IsSpectatorCtx);
  const title = current?.label ?? "";

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
      <div style={{ fontSize: title.length > 32 ? 15 : 20, fontWeight: "bold" }}>
        {title}
      </div>
      {!isSpectator ? <div style={{ fontSize: 12.5 }}>
        <MeetingTimerWrapper />
      </div> : null}
    </div>
  );
});

const TitleBarWrapper = React.memo(function TrackBarWrapper(): JSX.Element {
  const { current } = React.useContext(ContinuityMeetingContext);
  const self = useSelfMaybe();

  if (self == null) {
    return <TitleBar type="guest" />;
  }

  const eventUUID =
    current?.videoMeeting != null && "eventUUID" in current.videoMeeting
      ? current.videoMeeting.eventUUID
      : null;


  return <TitleBar type={eventUUID == null ? "call" : "event"} />;
});

const BottomBarLeft = styled("div", { gap: 15, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" });

const calculateDimensions = (trackCount: number, canvasWidth: number, canvasHeight: number) => {
  const aspectRatio = 16 / 9;
  let width, height;

  if (trackCount === 1) {
    width = canvasWidth;
    height = canvasWidth / aspectRatio;
    if (height > canvasHeight) {
      height = canvasHeight;
      width = height * aspectRatio;
    }
  } else if (trackCount === 2) {
    width = canvasWidth / 2;
    height = width / aspectRatio;
  } else if (trackCount <= 4) {
    width = canvasWidth / 2;
    height = width / aspectRatio;
  } else if (trackCount <= 6) {
    width = canvasWidth / 3;
    height = width / aspectRatio;
  } else if (trackCount <= 9) {
    width = canvasWidth / 3;
    height = width / aspectRatio;
  } else if (trackCount <= 12) {
    width = canvasWidth / 4;
    height = width / aspectRatio;
  } else {
    width = canvasWidth / 4;
    height = width / aspectRatio;
  }

  // Ensure the height does not exceed canvas height
  if (height * Math.ceil(trackCount / Math.floor(canvasWidth / width)) > canvasHeight) {
    height = canvasHeight / Math.ceil(trackCount / Math.floor(canvasWidth / width));
    width = height * aspectRatio;
  }

  return { width, height };
};

function useTrackGridElementStyles(): React.CSSProperties {
  const trackCount = React.useContext(TrackCountContext);
  const canvasDimensions = React.useContext(MeetingCanvasDimensionsCtx);

  console.log('>>> canvasDimensions', canvasDimensions);

  const { width, height } = React.useMemo(() => calculateDimensions(trackCount, canvasDimensions.width, canvasDimensions.height), [canvasDimensions.height, canvasDimensions.width, trackCount]);

  return {
    width: `${width}px`,
    height: `${height}px`,
  };
}

const TrackContainer = React.memo(function TrackContainer(props: React.PropsWithChildren): JSX.Element {
  const styles = useTrackGridElementStyles();

  return (
    <div style={styles}>
      {props.children}
    </div>
  );
});

const OuterTracksContainer = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const InnerTracksContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
});


const IsSpectatorCtx = React.createContext<boolean>(false);

export const ControlsBar = React.memo(function ControlsBar(): JSX.Element {
  const isSpectator = React.useContext(IsSpectatorCtx);
  const room = useRoomContext();

  const CameraToggle = useTrackToggle({ source: Track.Source.Camera });
  const MicrophoneToggle = useTrackToggle({
    source: Track.Source.Microphone,
  });
  const ScreenShareToggle = useTrackToggle({
    source: Track.Source.ScreenShare,
    captureOptions: { audio: true },
  });

  const [electronCaptureSources, setElectronCaptureSources] = React.useState<{
    name: string;
    id: string;
    thumbnailURL: string;
    isEmpty: boolean;
  }[] | null>(null);
  const startScreenShareWeb = useStartScreenShare();
  const startScreenShare = React.useCallback(async (params: {
    sourceId?: string;
    isBackground?: boolean;
  }) => {
    if (Electron.isElectron && params.sourceId == null) {
      const sources = await Electron.callAPI("getSources");
      console.log('>>> sources', sources);
      setElectronCaptureSources(sources);
    }
    else {
      // @ts-expect-error ---
      window.__hiylloScreenShareSourceId = params.sourceId;
      setElectronCaptureSources(null);
      await startScreenShareWeb({
        resolution: VideoPresets.h2160,
        audio: params.isBackground === true ? {
          channelCount: 2,
          echoCancellation: false,
          noiseSuppression: false,
        } : true,
      }, {
        audioPreset: params.isBackground === true ? AudioPresets.musicHighQualityStereo : undefined,
        dtx: !params.isBackground,
        red: !params.isBackground,
        name: params.isBackground === true ? "screenshare/background" : "screenshare/primary"
      });
    }
  }, [startScreenShareWeb]);
  const Disconnect = setupDisconnectButton(room);

  const [blurLoading, setBlurLoading] = React.useState(false);
  const [isBlurActive, setIsBlurActive] = React.useState(false);
  const enableBackgroundBlur = async (): Promise<void> => {
    if (blurLoading) return;

    const videoTrack = CameraToggle.track?.videoTrack;

    setBlurLoading(true);
    if (videoTrack != null) {
      if (isBlurActive) {
        await videoTrack.stopProcessor();
        setIsBlurActive(false);
      } else {
        await videoTrack.setProcessor(BackgroundBlur(10));
        await room.localParticipant.publishTrack(videoTrack);
        setIsBlurActive(true);
      }
    }
    setBlurLoading(false);
  };

  return (
    <>
      {electronCaptureSources != null ?
        <Modal onClose={() => setElectronCaptureSources(null)}>
          <div style={{ padding: 20, display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              {electronCaptureSources.map((source) => (
                <div key={source.id} style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 10, textAlign: "center", cursor: "pointer" }} onClick={() => startScreenShare({ sourceId: source.id })}>
                  {source.isEmpty ?
                    <div style={{ height: 200, width: 350, display: "flex", justifyContent: "center", alignItems: "center", background: "black" }}>
                      <FontAwesomeIcon icon={faHammerBrush} style={{ color: "white", fontSize: 75 }} />
                    </div>
                    :
                    <img src={source.thumbnailURL} style={{ height: 200, width: 200, objectFit: "contain" }} />
                  }
                  <div>{source.name}</div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
        : null}
      <ButtonsRow>
        {/* <CircleButton
      icon={faMicrophone}
      secondary
      size={30}
      square
      onClick={() => {
        //
      }}
    />
    <CircleButton
      icon={faVideoCamera}
      secondary
      size={30}
      square
      onClick={() => {
        //
      }}
    /> */}
        {!isSpectator ? <>
          <Button
            icon={MicrophoneToggle.enabled ? faMicrophone : faMicrophoneSlash}
            variant={ButtonVariant.narrow}
            onClick={() => MicrophoneToggle.toggle()}
            isSecondary
            label={MicrophoneToggle.enabled ? "Mute" : "Unmute"}
          />
          <Button
            icon={CameraToggle.enabled ? faVideoCamera : faVideoSlash}
            variant={ButtonVariant.narrow}
            onClick={() => CameraToggle.toggle()}
            isSecondary
            label={CameraToggle.enabled ? "Stop Camera" : "Start Camera"}
          />
          <Button
            variant={ButtonVariant.narrow}
            onClick={enableBackgroundBlur}
            icon={isBlurActive ? faSquareUser : faSquareUserHollow}
            isLoading={blurLoading}
            isSecondary
            label={isBlurActive ? "Disable Background Blur" : " Blur Background"}
          />
          <Button
            variant={ButtonVariant.narrow}
            onClick={ScreenShareToggle.enabled ? () => ScreenShareToggle.toggle() : (evt?: React.MouseEvent<Element, MouseEvent>) => startScreenShare({
              isBackground: evt?.nativeEvent.altKey === true
            })}
            icon={
              ScreenShareToggle.enabled ? faDisplaySlash : ScreenshareIcon
            }
            isLoading={ScreenShareToggle.pending}
            isSecondary
            label={ScreenShareToggle.enabled ? "Stop Sharing" : "Start Sharing"}
          />
        </> : null}
        <Button
          variant={ButtonVariant.narrow}
          icon={faTimes}
          onClick={() => Disconnect.disconnect()}
          color="danger"
          label="Leave"
        />
      </ButtonsRow>
    </>
  );
});

export const HiylloMeetUI = React.memo(function HiylloMeetUI(props: {
  spectator?: boolean;
  started?: Date;
}): JSX.Element {
  const { current } = React.useContext(ContinuityMeetingContext);
  const tracks = useTracks();
  const displayableTracks = React.useMemo(() => {
    return tracks.filter((track) => track.publication?.trackName !== "screenshare/background" && track.publication?.source !== Track.Source.ScreenShare);
  }, [tracks]);
  const screenShareTracks = React.useMemo(() => {
    return tracks
      .filter(isTrackReference)
      .filter((track) => track.publication.source === Track.Source.ScreenShare && track.publication.trackName !== "screenshare/background");
  }, [tracks]);
  const focusTrack = screenShareTracks.length === 1 ? screenShareTracks[0] : null;

  const [copyLinkSuccess, setCopyLinkSuccess] = React.useState(false);
  const copyMeetingLink = React.useCallback(() => {
    if (current?.videoMeeting?.meetingPassword != null) {
      const meetingLink = `${getRootURL()}/meet/${"meetingUUID" in current.videoMeeting
        ? current.videoMeeting.meetingUUID
        : current.videoMeeting.eventUUID
        }/${current.videoMeeting.meetingPassword}`;
      void window.navigator.clipboard.writeText(meetingLink);
      setCopyLinkSuccess(true);
    }
  }, [current]);
  const room = useRoomContext();
  const connected = room.state === ConnectionState.Connected;

  const eventUUID =
    current?.videoMeeting != null && "eventUUID" in current.videoMeeting
      ? current.videoMeeting.eventUUID
      : null;
  const EventWrapper =
    eventUUID == null ? React.Fragment : MeetingEventProvider;
  const hasConnectedRef = React.useRef(false);
  React.useEffect(() => {
    if (connected) {
      hasConnectedRef.current = true;
    }
  }, [connected]);

  return (
    <IsSpectatorCtx.Provider value={props.spectator === true}>
      <EventWrapper eventUUID={eventUUID ?? ""} started={props.started}>
        {!connected ? <DisconnectedIndicator hasConnected={hasConnectedRef.current} /> : null}
        <OuterContainer>
          <TilesContainer>
            <TrackCountContext.Provider value={displayableTracks.length}>
              {focusTrack != null ?
                <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                  <div style={{ width: "75%" }}>
                    <MeetingCanvas>
                      <ParticipantContextIfNeeded
                        participant={focusTrack.participant}
                      >
                        <TrackTile track={focusTrack} isScreenShare />
                      </ParticipantContextIfNeeded>
                    </MeetingCanvas>
                  </div>
                  <div style={{ width: "25%" }}>
                    <MeetingCanvas>
                      <OuterTracksContainer>
                        <InnerTracksContainer>
                          {displayableTracks.map(track => (
                            <TrackRefContext.Provider
                              value={track}
                              key={getTrackReferenceId(track)}
                            >
                              <TrackContainer>
                                <TrackTile track={track} />
                              </TrackContainer>
                            </TrackRefContext.Provider>
                          ))}
                        </InnerTracksContainer>
                      </OuterTracksContainer>
                    </MeetingCanvas>
                  </div>
                </div>
                :
                <MeetingCanvas>
                  <OuterTracksContainer>
                    <InnerTracksContainer>
                      {displayableTracks.map(track => (
                        <TrackRefContext.Provider
                          value={track}
                          key={getTrackReferenceId(track)}
                        >
                          <TrackContainer>
                            <TrackTile track={track} />
                          </TrackContainer>
                        </TrackRefContext.Provider>
                      ))}
                    </InnerTracksContainer>
                  </OuterTracksContainer>
                </MeetingCanvas>
              }
            </TrackCountContext.Provider>
          </TilesContainer>
          <BottomBar>
            <BottomBarLeft>
              <TitleBarWrapper />
              {props.spectator !== true ? <Button
                icon={faLink}
                variant={ButtonVariant.narrow}
                onClick={copyMeetingLink}
                isSecondary
                label="Copy Meeting Link"
                autoWidth
                success={copyLinkSuccess}
                successMessage="Copied!"
              /> : null}
            </BottomBarLeft>
            <ControlsBar />
          </BottomBar>
        </OuterContainer>
      </EventWrapper>
    </IsSpectatorCtx.Provider>
  );
});