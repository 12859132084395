import React from "react";
import {
  type ListTasksSlimTaskType,
  type TaskSizingType,
} from "../../../types/tasks/task-item";
import * as ArchiveTaskBP from "../../../blueprints/tasks/archive-task";
import * as ChangeTaskSprintBP from "../../../blueprints/tasks/change-task-sprint";
import * as UpdateTaskPriorityBP from "../../../blueprints/tasks/update-task-priority";
import {
  FixedSizingPillContext,
  FixedStatusPillContext,
  HighlightTaskContext,
} from "../kanban-view/task-kanban-view";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { AssigneePill, StatusPill, DueDatePill, useUpdateTaskAssignee, TaskPillsRow, TaskIcons } from "@hiyllo/omni-tasks";
import { CondensedDetailsPill } from "../kanban-view/condensed-details-pill";
import {
  ContextMenuContainer,
  ContextMenuItem,
  NestedContextMenuItem,
  useClearCtxMenus,
  useContextMenu,
} from "@hiyllo/ux/context-menu";

import {
  faArchive,
  faArrowDownToLine,
  faArrowUpArrowDown,
  faArrowUpRightFromSquare,
  faArrowUpToLine,
  faBed,
  faLink,
  faRunning,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { seamlessClient } from "../../../seamless-client";
import { useDeriveURL } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { openWindow } from "../../../platform/open-window";
import { useGetTask } from "@hiyllo/omni-tasks";
import { SprintsContext } from "../../tokyo/features/tasks/contexts";
import { SprintStatusEnum } from "../../../types/tasks/sprint";
import { useSelf } from "@hiyllo/omni-continuity/main";
import { Electron } from "../../../platform/electron";
import { motion, useMotionValue } from "framer-motion";
import { MouseState } from "@hiyllo/ux/button";
import { useMouseState } from "@hiyllo/ux/animation";
import { useShowAlert } from "@hiyllo/ux/dialogs";
import { HIYLLO_DEFAULT_PRIORITIES, TaskPriorityV3 } from "../../../types/tasks/tasks-organization";
import { styled } from "@hiyllo/ux/styled";

export const TaskCardContainer = styled<"div", { overdue?: boolean; isHighlighted?: boolean; translucent?: boolean }>("div", ({ $theme, overdue, isHighlighted, translucent }) => ({
  padding: overdue ? 8 : 10,
  borderWidth: overdue ? 2 : 0,
  borderColor: "#d3302f",
  borderStyle: "solid",
  borderRadius: 5,
  backgroundColor: isHighlighted === true ? $theme.midground : $theme.background3,
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 10,
  userSelect: "none",
  whiteSpace: "pre-wrap",
  color: $theme.foreground,
  opacity: translucent === true ? 0.3 : 1,
}));

export const HideTaskProjectLabelsCtx = React.createContext<boolean>(false);

export const TaskCtxMenuWrapper = React.memo(function TaskCtxMenuWrapper(props: React.PropsWithChildren<{
  isDragging?: boolean;
  onClick?: () => void;
  task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType;
  showDescription?: string;
  onMoveTaskToTop?: (() => void) | null;
  onMoveTaskToBottom?: (() => void) | null;
  onChanged?: () => void;
}>): JSX.Element {
  const { onMouseEnter, onMouseLeave, onMouseDown, onMouseUp, mouseState } = useMouseState();

  const overdue =
    props.task.status.closed !== true &&
    (props.task.dueDate?.date.valueOf() ?? Infinity) < Date.now();
  const isHighlighted =
    React.useContext(HighlightTaskContext) === props.task.uuid;
  const fixedSizingPill = React.useContext(FixedSizingPillContext);
  const fixedStatusPill = React.useContext(FixedStatusPillContext);
  const updateTaskSizingMutation = useUpdateTaskSizing();
  const cxMenu = useContextMenu({ positionMode: "fixed", zIndex: 101 });
  const contextSprints = React.useContext(SprintsContext);
  const activeSprints = React.useMemo(() => contextSprints.filter(s => s.status === SprintStatusEnum.active || s.status === SprintStatusEnum.new), [contextSprints]);
  const updateTaskAssignee = useUpdateTaskAssignee();
  const self = useSelf();
  const showAlert = useShowAlert();
  const updateTaskPriority = seamlessClient.useMutation<UpdateTaskPriorityBP.Plug>(UpdateTaskPriorityBP);

  const updatePriority = React.useCallback((priority: TaskPriorityV3) => {
    void updateTaskPriority.call({
      taskUUID: props.task.uuid,
      priority,
    }).then(props.onChanged);
  }, [props.onChanged, props.task.uuid, updateTaskPriority]);

  const assignSelf = React.useCallback(() => {
    void updateTaskAssignee.call({
      taskUUID: props.task.uuid,
      assigneeUserId: self.userId,
    })
      .then(() => {
        props.onChanged?.();
      })
      .catch(err => {
        void showAlert({
          title: "Error",
          message: err.message,
        });
      });
    cxMenu.close();
  }, [cxMenu, props, self.userId, showAlert, updateTaskAssignee]);

  const setSizing = React.useCallback(
    (sizing: TaskSizingType | null) => {
      void updateTaskSizingMutation.call({
        taskUUID: props.task.uuid,
        sizing,
      }).then(props.onChanged);;
    },
    [props.onChanged, props.task.uuid, updateTaskSizingMutation],
  );

  const onContextMenu = React.useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    cxMenu.open(
      evt as unknown as React.SyntheticEvent<HTMLButtonElement, MouseEvent>,
    );
  }, []);

  const archiveTaskMutation =
    seamlessClient.useMutation<ArchiveTaskBP.Plug>(ArchiveTaskBP);
  const archiveTask = React.useCallback(() => {
    void archiveTaskMutation.call({
      taskUUID: props.task.uuid,
    });
    cxMenu.close();
  }, [archiveTaskMutation, cxMenu, props.task.uuid]);

  const changeSprintMutation =
    seamlessClient.useMutation<ChangeTaskSprintBP.Plug>(
      ChangeTaskSprintBP,
    );
  const removeFromSprint = React.useCallback(() => {
    void changeSprintMutation.call({
      taskUUID: props.task.uuid,
      newSprintUUID: null,
    }).then(props.onChanged);;
    cxMenu.close();
  }, [changeSprintMutation, cxMenu, props.onChanged, props.task.uuid]);
  const addToSprint = React.useCallback((newSprintUUID: string) => {
    void changeSprintMutation.call({
      taskUUID: props.task.uuid,
      newSprintUUID,
    }).then(props.onChanged);
    cxMenu.close();
  }, [changeSprintMutation, cxMenu, props.onChanged, props.task.uuid]);

  const deriveURL = useDeriveURL();

  const openInNewTab = React.useCallback(() => {
    openWindow(
      deriveURL({
        feature: Features.tasks,
        params: {
          view: "task",
          taskUUID: props.task.uuid,
        },
      }),
    );
    cxMenu.close();
  }, [cxMenu, deriveURL, props.task.uuid]);

  const copyLink = React.useCallback(() => {
    void window.navigator.clipboard.writeText(
      deriveURL({
        feature: Features.tasks,
        params: {
          view: "task",
          taskUUID: props.task.uuid,
        },
      }),
    );
    cxMenu.close();
  }, [cxMenu, deriveURL, props.task.uuid]);

  const rotateX = useMotionValue(0);
  const rotateY = useMotionValue(0);
  const clearCtxMenus = useClearCtxMenus();

  return (
    <>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={faArchive}
            label="Archive Task"
            onClick={archiveTask}
          />
          <ContextMenuItem
            icon={faLink}
            label="Copy Link"
            onClick={copyLink}
          />
          {props.task.assigneeUserId !== self.userId ?
            <ContextMenuItem
              icon={faUserPlus}
              label="Assign to Me"
              onClick={assignSelf}
            />
            : null}
          {props.task.sprintUUID != null ? (
            <ContextMenuItem
              icon={faBed}
              label="Remove from Sprint"
              onClick={removeFromSprint}
            />
          ) : null}
          {props.task.sprintUUID == null && activeSprints.map(sprint => (
            <ContextMenuItem
              icon={faRunning}
              label={`Add to ${sprint.name}`}
              onClick={() => addToSprint(sprint.uuid)}
              key={sprint.uuid}
            />
          ))}
          <ContextMenuItem
            icon={faArrowUpRightFromSquare}
            label={Electron.isElectron ? "Open in New Window" : "Open in New Tab"}
            onClick={openInNewTab}
          />
          <NestedContextMenuItem
            icon={faArrowUpArrowDown}
            label="Change Priority"
          >
            {HIYLLO_DEFAULT_PRIORITIES.map(priority => (
              <ContextMenuItem
                icon={TaskIcons[priority.icon]}
                label={priority.label}
                onClick={() => updatePriority(priority)}
                key={priority.uuid}
              />
            ))}
          </NestedContextMenuItem>
          {props.onMoveTaskToTop != null ?
            <ContextMenuItem
              icon={faArrowUpToLine}
              label={"Move task to top"}
              onClick={() => {
                props.onMoveTaskToTop?.();
                cxMenu.close();
              }}
            />
            : null}
          {props.onMoveTaskToBottom != null ?
            <ContextMenuItem
              icon={faArrowDownToLine}
              label={"Move task to bottom"}
              onClick={() => {
                props.onMoveTaskToBottom?.();
                cxMenu.close();
              }}
            />
            : null}
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <div onContextMenu={onContextMenu}>
        {props.children}
      </div>
    </>
  );
});