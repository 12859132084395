import React from "react";
import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useThemeStyling } from "../../../ux/themings";
import { useTheme } from "@hiyllo/ux/theme";

export const LSText = (props: {
  label: string;
  icon: { fa: IconDefinition } | { element: JSX.Element } | null;
  skeleton?: boolean;
  right?: JSX.Element;
}): JSX.Element => {
  const $theme = useTheme();
  const colorFG = $theme.sidebar?.foregroundInactive ?? $theme.foregroundInactive;

  return (
    <div
      style={{
        padding: 20,
        paddingTop: 7.5,
        paddingBottom: 7.5,
        color: colorFG,
        fontFamily: "hiyllo",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {props.icon != null ? (
          <div
            style={{
              width: "1em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: props.skeleton ? colorFG : "transparent",
            }}
          >
            {"element" in props.icon ? (
              props.icon.element
            ) : (
              <FontAwesomeIcon icon={props.icon.fa} />
            )}
          </div>
        ) : null}
        <div
          style={{
            marginLeft: props.icon === null ? 0 : 20,
            fontSize: 16,
            letterSpacing: 1,
            background: props.skeleton ? colorFG : "transparent",
          }}
        >
          {props.label}
        </div>
      </div>
      {props.right}
    </div>
  );
};
