import { styled } from "@hiyllo/ux/styled";

export const TinyPillContainer = styled<
  "div",
  { overdue?: boolean; noLeftPadding?: boolean }
>("div", ({ overdue, noLeftPadding, $theme }) => ({
  background: overdue ? "#d3302f" : $theme.midground1,
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 20px -2.5px",
  display: "flex",
  flexDirection: "row",
  gap: 5,
  alignItems: "center",
  borderRadius: 20,
  paddingLeft: noLeftPadding === true ? 0 : 10,
  fontSize: 12,
  overflow: "hidden",
}));

export const PillContent = styled<"div", { overdue?: boolean }>(
  "div",
  ({ overdue, $theme }) => ({
    backgroundColor: overdue ? "#f44336" : "#5f5e62",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    gap: 5,
    cursor: "pointer",
    height: 26,
  }),
);
