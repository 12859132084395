import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { Card } from "@hiyllo/ux/surface";
import React from "react";

const Description = styled("div", {
  fontSize: 16,
  lineHeight: "1.25em",
  marginTop: 15,
});

const GradientBanner = styled("div", ({ $theme }) => ({
  padding: 40,
  borderRadius: 10,
  background: $theme.buttonBackground,
  textAlign: "center",
  fontSize: 36,
}));

const Spacer = styled("div", { height: 24 });

export const InfoCard = React.memo(function InfoCard(props: {
  label: string;
  title: string;
  description: Array<string | JSX.Element>;
}): JSX.Element {
  return (
    <Card
      $color="background2"
      style={{
        flexGrow: 1,
        maxWidth: 400,
      }}
    >
      <Description style={{ marginTop: 0 }}>
        <FontAwesomeIcon icon={faInfoCircle} />
        &nbsp;&nbsp;&nbsp;{props.label}
      </Description>
      <Spacer />
      <GradientBanner>
        <div>{props.title}</div>
      </GradientBanner>
      {props.description.map((d, i) => (
        <Description key={i}>{d}</Description>
      ))}
    </Card>
  );
});
