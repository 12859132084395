import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import React from "react";
import { type AlertType } from "@hiyllo/omni-common/src/types/alerts/alert";
import { seamlessClient } from "../../../../seamless-client";
import * as MarkAlertsReadBP from "../../../../blueprints/alerts/mark-alerts-read";
import { useNavigate } from "@hiyllo/omni-router";
import { navigateToAlertLink } from "../alerts-pill";
import {
  AlertBody,
  AlertCardContentContainer,
  AlertCardIconContainer,
  AlertCardMainSection,
  AlertCloseIcon,
  AlertDate,
  AlertTitle,
} from "./alert-card-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faEnvelope,
  faListCheck,
  faPhoneAlt,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import { AlertActions } from "./alert-actions";
import { AlertFeature } from "../../../../types/alerts/alert";
import { styled } from "@hiyllo/ux/styled";

const Container = styled("div", ({ $theme }) => ({
  color: $theme.foreground
}));

export const BasicAlertCard = React.memo(function BasicAlertCard(props: {
  isPopup?: boolean;
  onClose: () => void;
  alertsCountQuery?: UseMoopsyQueryRetValAny;
  alertsQuery?: UseMoopsyQueryRetValAny;
  alert: AlertType;
}): JSX.Element {
  const markAlertsRead =
    seamlessClient.useMutation<MarkAlertsReadBP.Plug>(
      MarkAlertsReadBP,
      { querySideEffects: [props.alertsCountQuery ?? null, props.alertsQuery ?? null] },
    );
  const eNav = useNavigate();

  const onNavigate = (): void => {
    void markAlertsRead.call({ alertUUIDs: [props.alert.uuid] });
    if (props.alert.link !== null) {
      navigateToAlertLink(eNav, props.alert.link);
      props.onClose();
    }
  };

  const onClickCloseIcon = React.useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      void markAlertsRead.call({ alertUUIDs: [props.alert.uuid] });
      if (props.isPopup === true) {
        props.onClose();
      }
    },
    [markAlertsRead, props],
  );

  return (
    <Container>
      <AlertCardMainSection onClick={onNavigate}>
        <AlertCardIconContainer>
          <FontAwesomeIcon
            icon={
              props.alert.feature === AlertFeature.mail ? faEnvelope :
                props.alert.icon === "phone"
                  ? faPhoneAlt
                  : props.alert.icon === "task"
                    ? faListCheck
                    : faComments
            }
          />
        </AlertCardIconContainer>
        <AlertCardContentContainer>
          <AlertTitle>{props.alert.title}</AlertTitle>
          {props.alert.body != null ? (
            <AlertBody>
              {props.alert.body.length > 97
                ? props.alert.body.slice(0, 97) + "..."
                : props.alert.body}
            </AlertBody>
          ) : null}
          {Math.abs(moment().diff(props.alert.date, "minutes")) > 2 ? (
            <AlertDate>
              {moment(props.alert.date).format("h:mm a, MMM Do")}
            </AlertDate>
          ) : null}
          <AlertActions
            alert={props.alert}
            alertsCountQuery={props.alertsCountQuery}
            alertsQuery={props.alertsQuery}
          />
        </AlertCardContentContainer>
        <div
          style={{
            width: 0,
            flexGrow: 1,
          }}
        />
        <div>
          <AlertCloseIcon onClick={onClickCloseIcon}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </AlertCloseIcon>
        </div>
      </AlertCardMainSection>
    </Container>
  );
});
