import React from "react";

import { Select } from "@hiyllo/ux/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { TaskPriorityV3 } from "@hiyllo/omni-common/src/types/tasks/tasks-organization";
import { HIYLLO_DEFAULT_PRIORITIES } from "../../../types/tasks/tasks-organization";
import { TaskIcons, usePriorities, usePriorityDisplay } from "@hiyllo/omni-tasks/main";

interface PropsType {
  priority: TaskPriorityV3;
  onChangePriority: null | ((newValue: TaskPriorityV3) => void);
  projectUUID: string;
}

export const PriorityPill = React.memo(function PriorityPill({
  priority,
  onChangePriority,
  projectUUID
}: PropsType): JSX.Element {
  const priorities = usePriorities(projectUUID);
  const options = React.useMemo(() => {
    return priorities.map(p => ({
      icon: TaskIcons[p.icon],
      label: p.label,
      value: p.uuid
    }));
  }, [priorities]);
  const selectedOption = options.find((o) => o.value === priority.uuid);
  const priorityDisplay = usePriorityDisplay(priority);

  return (
    <Select
      options={options}
      value={priority.uuid}
      disabled={onChangePriority === null}
      onChangeValue={
        onChangePriority != null
          ? (v) => onChangePriority(
            HIYLLO_DEFAULT_PRIORITIES.find((p) => p.uuid === v)!
          )
          : undefined
      }
      containerStyle={{ width: "min-content" }}
      expandedContainerStyle={{ borderRadius: 5 }}
      customStaticElement={
        <div
          style={{
            background: priorityDisplay.color,
            color: "white",
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
            height: 25,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          {selectedOption != null ? (
            <>
              <FontAwesomeIcon icon={selectedOption.icon} fixedWidth />
              {onChangePriority != null ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : null}
            </>
          ) : null}
        </div>
      }
    />
  );
});
