import React from "react";
import { ContainerIcon } from "@hiyllo/omni-stuff";
import { useNavigate } from "@hiyllo/omni-continuity";
import * as FetchElementChainBP from "../../../blueprints/stuff/fetch-element-chain";

import { seamlessClient } from "../../../seamless-client";
import { ErrorSplash } from "../../../ux/error-splash";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Features } from "../../../types/navigation/features";
import { LSButton } from "../../tokyo/components/ls-button";
import { useTheme } from "@hiyllo/ux/theme";

export const Chain = React.memo(function Chain(props: {
  elementUUID: string;
  removePadding?: boolean;
}): JSX.Element {
  const $theme = useTheme();
  const elementChainQuery =
    seamlessClient.useQuery<FetchElementChainBP.Plug>(
      FetchElementChainBP,
      {
        elementUUID: props.elementUUID,
      },
    );
  const nav = useNavigate();

  if (elementChainQuery.error != null) {
    if (elementChainQuery.error.code === 403) {
      /**
       * Dont surface 403 errors, the user may be viewing a document in a folder they don't have access to
       */
      return <div />;
    }
    return <ErrorSplash error={elementChainQuery.error} />;
  }
  if (elementChainQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <div style={{ marginLeft: props.removePadding ? -20 : 0 }}>
      {elementChainQuery.data.containers.map((container, i) => (
        <LSButton
          isActive={i === 0}
          icon={{
            element: <ContainerIcon type={container.type} color={i === 0 ? ($theme.sidebar?.foregroundActive ?? "") : ($theme.sidebar?.foregroundInactive ?? "")} size={30} />
          }}
          label={container.name}
          key={container.uuid}
          link={{
            feature: Features.stuff,
            params: {
              view: "container",
              uuid: container.uuid,
            },
          }}
        />
      ))}
      <LSButton
        isActive={false}
        icon={{
          element: <ContainerIcon type={'arbitrary-folder'} color={$theme.sidebar?.foregroundInactive ?? ""} size={30} />
        }}
        label={'Stuff'}
        link={{
          feature: Features.stuff,
          params: {
            view: 'container',
            uuid: null
          },
        }}
      />
    </div>
  );
});