import { faBell, faBellSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, Select } from "@hiyllo/ux/select";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { motion } from "framer-motion";
import React from "react";
import { useMuted, useSetMuted } from "../../../../main/mute-provider";
import { AnimateChangeInWidth } from "@hiyllo/ux/animation";

const OuterContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Container = styled<
  "div",
  // @ts-expect-error ---
  React.ComponentProps<typeof motion.div>>(motion.div, ({ $theme }) => ({
    height: 40,
    color: $theme.foreground,
    width: "100%",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
    flexShrink: 0
  }));

const MuteControls = styled("div", ({ $theme }) => ({
  width: 190,
  height: 40,
  borderRadius: 10,
  overflow: "hidden",
}));

const MUTE_SELECT_OPTIONS: OptionType[] = [
  {
    value: "indefinite",
    label: "Muted until I unmute",
  }
];

export const MuteController = React.memo(function MuteController(): JSX.Element {
  const $theme = useTheme();
  const muted = useMuted();
  const setMuted = useSetMuted();

  const containerStyle = React.useMemo(() => (
    muted ? { width: 40, backgroundColor: "#d32f2f" } : { width: 40, backgroundColor: $theme.background3 }
  ), [muted]);

  return (
    <OuterContainer>
      <Container animate={containerStyle} onClick={() => setMuted(v => !v)}>
        <FontAwesomeIcon icon={muted ? faBellSlash : faBell} />
      </Container>
      <motion.div animate={{ width: muted ? 10 : 0, flexShrink: 0 }} />
      {muted ? <MuteControls>
        <div
          style={{
            color: $theme.foreground,
            width: 190,
            height: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "hiyllo",
          }}
        >
          <div style={{ paddingLeft: 5 }}>
            <div style={{ fontSize: 15 }}>Push alerts paused on this device</div>
          </div>
        </div>
      </MuteControls> : null}
    </OuterContainer>
  );
});