import { MouseInteraction } from "@hiyllo/ux/animation";
import { styled, styledRN } from "@hiyllo/ux/styled";
import React from "react";
import { View } from "react-native";

export const BoardColumnsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 15,
  height: "100%",
  padding: 5
});

export const BoardColumnContainer = styled("div", {
  // backgroundColor: "blue",
});

export const BoardColumn = styled<"div", { frameless?: boolean }>(
  "div",
  ({ $theme, frameless }) => ({
    // height: "100%",
    // background: "red",
    display: "flex",
    flexDirection: "column",
  }),
);

export const CardsContainer = styled("div", {
  // display: "flex",
  // flexDirection: "column",
  // gap: 5
  // height: 0,
  // flexGrow: 1,
  // overflowY: "auto",
  // overflowX: "visible"
});

export const ColumnHeader = styled("div", {
  fontSize: 15,
  padding: 0,
  paddingBottom: 5,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ColumnHeaderTitleText = styled("div", ({ $theme }) => ({
  userSelect: "none",
  color: $theme.foreground,
}));

const SubtleButtonElem = styled("div", ({ $theme }) => ({
  width: 25,
  height: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 14,
  background: $theme.midground1,
  color: $theme.foreground,
  borderRadius: 5,
  cursor: "pointer",
}));

export const SubtleButton = React.memo(function SubtleButton(props: {
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <SubtleButtonElem onClick={props.onClick}>
      {/* @ts-expect-error --- */}
      <MouseInteraction factorShift={7.5}>
        {props.children}
      </MouseInteraction>
    </SubtleButtonElem>
  );
});

export const ProjectName = styled("div", {
  fontSize: 10,
  opacity: 0.8,
  lineHeight: "1em",
});
