/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  uuid: string;
  description: string;
};
export type ResponseType = void;
export const Endpoint = "tasks/update-project-description";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};

export const RateLimitingConfig = {
  calls: 5,
  per: 750,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"description":{"type":"string"}},"required":["description","uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}