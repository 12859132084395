import React from "react";
import fuzzysort from "fuzzysort";
import { Modal } from "@hiyllo/ux/modal";
import { FindView } from "@hiyllo/ux/find";
import { Button } from "@hiyllo/ux/button";
import { TaskView } from "../views/task-view";
import { usePath } from "@hiyllo/omni-router";
import { DragDropContext } from "@hello-pangea/dnd";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { useNavigateTo, useSelf } from "@hiyllo/omni-continuity";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { type OnChangeOrderFnType, useDNDOnDragEnd, useOrder } from "@hiyllo/omni-tasks";

import { Features } from "../../../types/navigation/features";
import { HideTaskProjectLabelsCtx } from "../components/task-card";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { type TasksEDataParamsType } from "../../../types/navigation/edata";
import { TaskStatusV3, type TaskOrderType } from "../../../types/tasks/tasks-organization";
import { BoardColumn, BoardColumnContainer, BoardColumnsContainer } from "./components";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { ListSection } from "./list-section";

export const HighlightTaskContext = React.createContext<string | null>(null);
export const FixedSizingPillContext = React.createContext<boolean>(false);
export const FixedStatusPillContext = React.createContext<boolean>(false);
export const KanbanFramelessContext = React.createContext<boolean>(false);

const KanbanSkeleton = React.memo(function KanbanSkeleton(): JSX.Element {
  return (
    <BoardColumnsContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
      <BoardColumnContainer>
        <BoardColumn>
          <LoadingSpinnerFullView />
        </BoardColumn>
      </BoardColumnContainer>
    </BoardColumnsContainer>
  );
});

export const TaskListView = React.memo(function TaskListView(props: {
  hideTaskProjectLabels?: boolean;
  order: TaskOrderType;
  onChangeOrder: OnChangeOrderFnType;
  tasks: ListTasksSlimTaskType[];
  hideHeader?: boolean;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
  isReady?: boolean;
  columns: TaskStatusV3[] | null;
  projectUUID?: string | null;
}): JSX.Element {
  const [search, setSearch] = React.useState("");
  const params = usePath().params as TasksEDataParamsType;

  const [selectedTaskUUID, setSelectedTaskUUID] = React.useState<string | null>(
    params != null && "taskUUID" in params ? params.taskUUID ?? null : null,
  );
  const order = useOrder(props.order, props.tasks);

  const onSelectTask = React.useCallback(
    (task: ListTasksSlimTaskType) => {
      if (props.onClickTask != null) {
        const result = props.onClickTask(task);
        if (result) {
          return;
        }
      }

      setSelectedTaskUUID(task.uuid);
    },
    [props],
  );

  const onDragEnd = useDNDOnDragEnd({
    order,
    onChangeOrder: props.onChangeOrder,
    tasks: props.tasks,
  });
  const self = useSelf();

  const createTask = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "create-task",
      projectUUID:
        params != null && "projectUUID" in params ? params.projectUUID : null,
      sprintUUID:
        params != null && "sprintUUID" in params ? params.sprintUUID : null,
      assigneeId: params == null || params?.view === 'ic-view' ? self.userId : null,
    },
  });

  const tasks = React.useMemo(() => {
    if (search === "") {
      return props.tasks;
    }

    return fuzzysort.go(search, props.tasks, {
      keys: ["title", "shortId"],
    }).map((result) => result.obj);
  }, [props.tasks, search]);

  if (props.tasks.length === 0) {
    if (props.isReady === true) {
      return (
        <EmptySplash
          icon={faEmptySet}
          label="No Tasks Here"
          hint="Click the + in the sidebar or use the button below to create a task"
          afterElement={
            <Button
              label="Create Task"
              onClick={createTask}
              style={{ marginTop: 5 }}
            />
          }
        />
      );
    } else {
      return <KanbanSkeleton />;
    }
  }

  return (
    <>
      <HideTaskProjectLabelsCtx.Provider
        value={props.hideTaskProjectLabels === true}
      >
        {selectedTaskUUID !== null ? (
          <Modal onClose={() => setSelectedTaskUUID(null)}>
            <TaskView uuid={selectedTaskUUID} />
          </Modal>
        ) : null}

        <DragDropContext onDragEnd={onDragEnd}>
          <BoardColumnsContainer>
            {/* <FindView
              placeholder="Search tasks..."
              onChangeValue={setSearch}
              value={search}
            /> */}
            {props.columns === null ? (
              <ListSection
                tasks={tasks}
                order={[
                  ...(order.todo ?? []),
                  ...(order.inprogress ?? []),
                  ...(order.inreview ?? []),
                  ...(order.done ?? []),
                  ...(order.wontdo ?? []),
                ]}
                onSelectTask={onSelectTask}
                id="all"
                label="All"
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ) : props.columns.map(column => (
              <ListSection
                key={column.uuid}
                tasks={tasks}
                order={order[column.uuid] ?? []}
                onSelectTask={onSelectTask}
                id={column.uuid}
                label={column.label}
                onChangeOrder={props.onChangeOrder}
                currentOrder={order}
                hideHeader={props.hideHeader}
              />
            ))}
          </BoardColumnsContainer>
        </DragDropContext>
      </HideTaskProjectLabelsCtx.Provider>
    </>
  );
});
