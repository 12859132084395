import { styled } from "@hiyllo/ux/styled";

export const LSTinyButton = styled("div", ({ $theme }) => ({
  borderColor: $theme.sidebar?.foregroundInactive,
  color: $theme.sidebar?.foregroundInactive,
  borderWidth: 1,
  borderRadius: 5,
  fontSize: 10,
  borderStyle: "solid",
  padding: "2.5px 5px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  cursor: "pointer",
  userSelect: "none",
}));
