import React from "react";
import { Typography } from "@hiyllo/ux/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifiSlash } from "@fortawesome/pro-light-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

const Container = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1,
  height: "100%",
  width: "100%",
  pointerEvents: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const InnerContainer = styled("div", {
  height: 250,
  width: 250,
  backdropFilter: "blur(10px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 5,
  background: "rgba(175, 175, 175, 0.1)",
  color: "white",
  borderRadius: 20,
  padding: 15,
  textAlign: "center",
});

export const DisconnectedIndicator = React.memo(
  function DisconnectedIndicator(props: {
    hasConnected?: boolean;
  }): JSX.Element {
    return (
      <Container>
        {props.hasConnected === false ?
          <InnerContainer>
            <Typography.HeaderIcon>
              <LoadingSpinner />
            </Typography.HeaderIcon>
            <Typography.SubHeader style={{ marginBottom: 0 }}>Connecting...</Typography.SubHeader>
          </InnerContainer>
          :
          <InnerContainer>
            <Typography.HeaderIcon>
              <FontAwesomeIcon icon={faWifiSlash} />
            </Typography.HeaderIcon>
            <Typography.SubHeader style={{ marginBottom: 0 }}>Connection Lost</Typography.SubHeader>
            <Typography.Paragraph>
              Please check your internet connection. Attempting to reconnect...
            </Typography.Paragraph>
          </InnerContainer>
        }
      </Container>
    );
  },
);
