import React from "react";
import {
  isTrackReference,
  type TrackReferenceOrPlaceholder,
} from "@livekit/components-core";
import { styled } from "@hiyllo/ux/styled";
import {
  ConnectionQualityIndicator,
  TrackMutedIndicator,
  useEnsureParticipant,
  useIsMuted,
  useMaybeTrackRefContext,
  VideoTrackProps,
} from "@livekit/components-react";
import { RemoteTrackPublication, Track } from "livekit-client";
import { NoVideoPlaceholder } from "./no-video-placeholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDisplay } from "@fortawesome/pro-light-svg-icons";
import * as useHooks from 'usehooks-ts';
import { useMediaTrackBySourceOrName } from "../hooks/use-media-track-by-source-or-name";

export const TrackCountContext = React.createContext(1);

const TrackTileElement = styled<"div", { totalTracks: number }>(
  "div",
  ({ $theme, totalTracks }) => ({
    background: $theme.background3,
    position: "relative",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    // flexBasis: totalTracks === 1 ? "100%" : "50%",
    // flexShrink: 1,
    // flexGrow: 1,
  }),
);

const VideoTrack = React.memo(function VideoTrack({
  onTrackClick,
  onClick,
  onSubscriptionStatusChanged,
  trackRef,
  name,
  publication,
  source,
  participant: p,
  manageSubscription,
  ...props
}: VideoTrackProps) {
  // TODO: Remove and refactor all variables with underscore in a future version after the deprecation period.
  const maybeTrackRef = useMaybeTrackRefContext();
  const _name = trackRef?.publication?.trackName ?? maybeTrackRef?.publication?.trackName ?? name;
  const _source = trackRef?.source ?? maybeTrackRef?.source ?? source;
  const _publication = trackRef?.publication ?? maybeTrackRef?.publication ?? publication;
  const _participant = trackRef?.participant ?? maybeTrackRef?.participant ?? p;
  if (_source === undefined) {
    throw new Error('VideoTrack: You must provide a trackRef or source property.');
  }

  const isScreenShare = source === Track.Source.ScreenShare || trackRef?.source === Track.Source.ScreenShare || maybeTrackRef?.source === Track.Source.ScreenShare;
  const isSelfCamera = source === Track.Source.Camera && _participant?.isLocal === true;

  const participant = useEnsureParticipant(_participant);

  const mediaEl = React.useRef<HTMLVideoElement>(null);

  const intersectionEntry = useHooks.useIntersectionObserver(mediaEl, {});

  const debouncedIntersectionEntry = useHooks.useDebounce(intersectionEntry, 3000);

  React.useEffect(() => {
    if (
      manageSubscription &&
      _publication instanceof RemoteTrackPublication &&
      debouncedIntersectionEntry?.isIntersecting === false &&
      intersectionEntry?.isIntersecting === false
    ) {
      _publication.setSubscribed(false);
    }
  }, [debouncedIntersectionEntry, _publication, manageSubscription]);

  React.useEffect(() => {
    if (
      manageSubscription &&
      _publication instanceof RemoteTrackPublication &&
      intersectionEntry?.isIntersecting === true
    ) {
      _publication.setSubscribed(true);
    }
  }, [intersectionEntry, _publication, manageSubscription]);

  const {
    elementProps,
    publication: pub,
    isSubscribed,
  } = useMediaTrackBySourceOrName(
    { participant, name: _name, source: _source, publication: _publication },
    {
      element: mediaEl,
      props,
    },
  );

  React.useEffect(() => {
    onSubscriptionStatusChanged?.(!!isSubscribed);
  }, [isSubscribed, onSubscriptionStatusChanged]);

  const clickHandler = (evt: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    onClick?.(evt);
    onTrackClick?.({ participant, track: pub });
  };

  return <video ref={mediaEl} {...elementProps} style={{ height: "100%", width: "100%", objectFit: isScreenShare ? "contain" : "cover", transform: isSelfCamera ? "rotateY(180deg)" : "" }} muted={true} onClick={clickHandler}></video>;
});


const CornerInfo = styled("div", {
  position: "absolute",
  bottom: 10,
  right: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 7.5,
});

export type MeetingUserType =
  | { type: "user"; name: string; userId: string }
  | { type: "guest"; name: string };

export const TrackTile = React.memo(function TrackTile(props: {
  track: TrackReferenceOrPlaceholder;
  isScreenShare?: boolean;
  screenShareActive?: boolean;
}): JSX.Element {
  const user: MeetingUserType = React.useMemo(() => {
    try {
      return JSON.parse(decodeURIComponent(props.track.participant.identity));
    } catch (e) {
      return { type: "guest", name: "Guest" };
    }
  }, [props.track.participant.identity]);
  const cameraMuted = useIsMuted(Track.Source.Camera);

  const showVideoTrack = React.useMemo(() => {
    return (
      isTrackReference(props.track) &&
      (props.isScreenShare === true || !cameraMuted)
    );
  }, [cameraMuted, props.isScreenShare, props.track]);

  const totalTracks = React.useContext(TrackCountContext);

  return (
    <div
      style={{
        height: props.screenShareActive === true ? undefined : "100%",
        width: "100%",
      }}
      className="HiylloMeetTile"
    >
      <TrackTileElement totalTracks={totalTracks}>
        {showVideoTrack ? (
          <VideoTrack {...props.track} />
        ) : (
          <NoVideoPlaceholder user={user} />
        )}
        <CornerInfo>
          {user.name}
          {props.isScreenShare === true ? "'s Screen" : ""}
          {props.isScreenShare === true ? (
            <FontAwesomeIcon icon={faDisplay} fixedWidth />
          ) : (
            <>
              <TrackMutedIndicator
                source={Track.Source.Microphone}
                style={{ marginRight: 0 }}
              />
              <TrackMutedIndicator source={props.track.source} />
            </>
          )}
          <ConnectionQualityIndicator style={{ height: 16 }} />
        </CornerInfo>
      </TrackTileElement>
    </div>
  );
});
