import React from "react";
import { useSelf } from "@hiyllo/omni-continuity";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import {
  faAngleLeft,
  faBellSlash,
  faBug,
  faCalendar,
  faCogs,
  faComments,
  faContactBook,
  faEnvelope,
  faFolder,
  faMoneyCheckDollar,
  faSearch,
  faSitemap,
  faSquareKanban,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { Dock } from "@hiyllo/ux/tokyo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertsPill } from "./alerts-pill";
import { useConfig } from "../../../platform/config/config-context";
import { Features } from "../../../types/navigation/features";
import { useNavigate, useNavigateTo, usePath } from "@hiyllo/omni-router";
import { styled } from "@hiyllo/ux/styled";
import { useSearchOverlay } from "../../../main/search-provider";
import {
  useWrappedPopOver,
} from "@hiyllo/ux/context-menu";
import { SidebarTakeover } from "./dock/sidebar-takeover";
import { SelfImage } from "./dock/self-image";
import { NymblIconLight } from "@hiyllo/icons/main";
import { IS_BETA_ENV, useShowNymblHome } from "../../../platform/xp";
import { MiniFeedbackForm } from "../../feedback/mini-feedback-form";
import { BuiltInFolderEnum } from "../../../types/mail/organization/builtin-folders";
import { useAlertsContext } from "@hiyllo/omni-alerts";
import { motion } from "framer-motion";
import { useMuted } from "../../../main/mute-provider";
import { faSlashForward } from "@fortawesome/pro-solid-svg-icons";
import { useCommandPalette } from "../../../main/command-palette-provider";

const SidebarCloseButton = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  color: $theme.foreground,
  height: 40,
  width: 40,
  borderRadius: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LeftDockTopBar = styled<"div">("div", {
  display: "flex",
  flexDirection: "column",
  gap: 10,
  alignItems: "center",
  overflowY: "auto",
  marginBottom: 15,
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  // height: 0,
});

const LeftDockBottomBar = styled<"div">("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,
  cursor: "pointer",
});

const DockElement = styled("div", ({ $theme }) => ({
  width: 80,
  flexGrow: 0,
  flexShrink: 0,
  height: "calc(100% - 40px)",
  paddingTop: 20,
  paddingBottom: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  background: $theme.background1,
}));

export const LeftDock = (): JSX.Element => {
  const path = usePath();
  const self = useSelf();
  const eNavigate = useNavigate();
  const config = useConfig();
  const searchOverlay = useSearchOverlay();
  const [showSidebar, setShowSidebar] = React.useState(false);
  const { breakdown } = useAlertsContext();

  const onShowSidebar = React.useCallback(() => {
    setShowSidebar(true);
  }, []);

  const onHideSidebar = React.useCallback(() => {
    setShowSidebar(false);
  }, []);

  const onClickNymblHome = useNavigateTo({
    feature: Features.home,
    params: null,
  });

  const onClickMail = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.inbox,
    },
  });

  const onClickChat = useNavigateTo({
    feature: Features.chat,
    params: null,
  });

  const onClickTasks = useNavigateTo({
    feature: Features.tasks,
    params: null,
  });

  const onClickCalendar = useNavigateTo({
    feature: Features.calendar,
    params: null,
  });

  const isSolo = config.isSolo === true;
  const showNymblHome = useShowNymblHome();
  const {
    isOpen: feedbackIsOpen,
    open: openFeedback,
    close: closeFeedback,
    CXMenuContainer,
    ref,
  } = useWrappedPopOver<HTMLDivElement>({
    offset: {
      top: 40,
      left: 60,
    },
    displayMode: "fixed",
    disableMaxHeight: true,
  });
  const commandPalette = useCommandPalette();

  return (
    <LayoutGroup>
      <>
        <CXMenuContainer>
          <div style={{ position: "relative" }}>
            <div
              style={{
                zIndex: 10,
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            >
              <MiniFeedbackForm onClose={closeFeedback} />
            </div>
          </div>
        </CXMenuContainer>
        <Dock.Container className="Dock">
          <LeftDockTopBar className="no-scroll-bar" id="dock-container">
            <Dock.Button
              icon={faSearch}
              onClick={searchOverlay.toggle}
              isActive={false}
              tooltip="Search Everything"
            />
            <Dock.Button
              icon={faSlashForward}
              onClick={() => commandPalette.set({ openedViaButton: true })}
              isActive={false}
              tooltip="Command Palette"
            />

            {showNymblHome ? (
              <Dock.Button
                icon={NymblIconLight}
                onClick={onClickNymblHome}
                isActive={path.feature === Features.home}
                tooltip="Home"
              />
            ) : null}

            {!isSolo ? (
              <Dock.Button
                icon={faComments}
                onClick={onClickChat}
                isActive={path.feature === Features.chat}
                tooltip="Chat"
                alertCount={breakdown?.chat ?? 0}
              />
            ) : null}

            {config.tasks?.enabled !== false ? (
              <Dock.Button
                icon={faSquareKanban}
                onClick={onClickTasks}
                isActive={path.feature === Features.tasks}
                tooltip="Tasks & Projects"
                alertCount={breakdown?.tasks ?? 0}
              />
            ) : null}

            <Dock.Button
              icon={faCalendar}
              onClick={onClickCalendar}
              isActive={path.feature === Features.calendar}
              tooltip="Calendar"
              alertCount={breakdown?.calendar ?? 0}
            />
            {!isSolo ? (
              <Dock.Button
                icon={faEnvelope}
                onClick={onClickMail}
                isActive={path.feature === Features.mail}
                tooltip="Mail"
                alertCount={breakdown?.mail ?? 0}
              />
            ) : null}

            <Dock.Button
              icon={faFolder}
              onClick={() => {
                eNavigate({
                  feature: Features.stuff,
                  params: {
                    view: "container",
                    uuid: null,
                  },
                });
              }}
              isActive={path.feature === Features.stuff}
              tooltip="Stuff"
            />

            {!isSolo ? (
              <Dock.Button
                icon={faSitemap}
                onClick={() => {
                  eNavigate({
                    feature: Features.organization,
                    params: { view: "dashboard" },
                  });
                }}
                isActive={path.feature === Features.organization}
                tooltip="Organization"
              />
            ) : null}

            {IS_BETA_ENV ? (
              <Dock.Button
                icon={faMoneyCheckDollar}
                onClick={() => {
                  eNavigate({
                    feature: Features.talent,
                    params: {
                      view: "ic-dashboard"
                    },
                  });
                }}
                isActive={path.feature === Features.talent}
                tooltip="Pay"
              />
            ) : null}

            {/* {!isSolo ? (
              <Dock.Button
                icon={faContactBook}
                onClick={() => {
                  eNavigate({
                    feature: Features.contacts,
                    params: { view: 'contacts-list' },
                  });
                }}
                isActive={path.feature === Features.contacts}
                tooltip="Contacts"
              />
            ) : null} */}

            {self.isAdmin ? (
              <Dock.Button
                icon={faCogs}
                onClick={() => {
                  eNavigate({
                    feature: Features.workspaceConfig,
                    params: null,
                  });
                }}
                isActive={path.feature === Features.workspaceConfig}
                tooltip="Workspace Settings"
              />
            ) : null}
            {/* <Dock.Button
              icon={faQuestionCircle}
              onClick={onClickSupport}
              isActive={path.feature === Features.support}
              tooltip="Help & Support"
            /> */}
            <div ref={ref}>
              <Dock.Button
                icon={faBug}
                onClick={openFeedback}
                isActive={feedbackIsOpen}
                tooltip="Send Feedback"
              />
            </div>
          </LeftDockTopBar>
          <LeftDockBottomBar>

            <AlertsPill />

            {!showSidebar ? (
              <SidebarCloseButton>
                <motion.div
                  whileHover={{
                    x: 5
                  }}
                  className="SidebarUserImage"
                >
                  <SelfImage onClick={onShowSidebar} />
                </motion.div>
              </SidebarCloseButton>
            ) : (
              <SidebarCloseButton onClick={onHideSidebar}>
                <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
              </SidebarCloseButton>
            )}
          </LeftDockBottomBar>
        </Dock.Container>
        <SidebarTakeover
          showSidebar={showSidebar}
          onCloseSidebar={onHideSidebar}
        />
      </>
    </LayoutGroup>
  );
};
