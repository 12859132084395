import { MoopsyMutation } from "@moopsyjs/react";
import { SESSION_TOKEN_KEY, seamlessClient } from "../../../seamless-client";
import { emitToNative } from "../../native-bridge";
import * as LogoutBP from "../../../blueprints/accounts/user/logout";
import * as GetFederationBP from "../../../blueprints/federation/get-federation";

export async function logout(reason?: "session-expired"): Promise<void> {
  const mutation = new MoopsyMutation<LogoutBP.Plug>(
    seamlessClient,
    LogoutBP,
    {
      querySideEffects: [],
    },
    false
  );

  const tokenData = window.localStorage.getItem(SESSION_TOKEN_KEY);

  if (tokenData != null) {
    try {
      const token = JSON.parse(tokenData).token;
      await mutation.call({
        token,
      });
    } catch (e) {
      console.error(e);
    }
  }

  const dbs = await window.indexedDB.databases();

  dbs.forEach((db) => {
    if (db.name) {
      window.indexedDB.deleteDatabase(db.name);
    }
  });

  window.localStorage.clear();
  emitToNative({ event: "logout" });

  const getFederationMutation = new MoopsyMutation<GetFederationBP.Plug>(seamlessClient, GetFederationBP, { querySideEffects: [] }, false);

  void getFederationMutation.call(null).then(res => {
    if (reason === "session-expired") {
      window.location.reload();
    }
    else {
      window.location.href = `https://${res.federation.slug}.f.hiyllo.cloud/logout`;
    }
  });
}
