import React from "react";
import * as StartSamlSSOLoginBP from "../../../blueprints/accounts/login/start-hiyllo-auth-login";
import { seamlessClient } from "../../../seamless-client";
import { LoadingPage } from "@hiyllo/ux/standard-loader";
import { useTenant } from "@hiyllo/omni-continuity/main";

export const InitiateSSOLoginView = (props: { email: string }): JSX.Element => {
  const tenant = useTenant();
  const contextQuery =
    seamlessClient.useQuery<StartSamlSSOLoginBP.Plug>(
      StartSamlSSOLoginBP,
      null,
    );

  React.useEffect(() => {
    if (contextQuery.data) {
      if (window.location.host === "solo.hiyllo.io" || window.location.host === "hylo.hiyllo.work") {
        const path =
          `https://www.hiyllo.cloud/ia/${contextQuery.data.context}` + "?then=" + window.location.href;
        let finalPath = window.location.host === "hylo.hiyllo.work" ? path : `https://www.hiyllo.cloud/f/login/${tenant}?then=${encodeURIComponent(path)}`;

        if (props.email != null && props.email !== "") {
          finalPath += "&em=" + props.email;
        }

        window.location.href = finalPath;
      }
      else {
        window.location.href = `https://${contextQuery.data.federation ?? tenant}.f.hiyllo.cloud/ia/${window.location.host}?then=${encodeURIComponent(window.location.href)}${props.email != null && props.email !== "" ? `&em=${props.email ?? ""}` : ""}`;
      }
    }
  }, [contextQuery.data, props.email, tenant]);

  return <LoadingPage />;
};
