import React, { useImperativeHandle } from "react";

import * as RetrieveBookATimeSchedulesBP from "../../../blueprints/calendar/book-a-time/retrieve-book-a-time-schedules";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { styled } from "@hiyllo/ux/styled";
import {
  type BookATimeSlotDuration,
  type BookATimeSchedule,
  DayString,
  type BookATimeRange,
} from "../../../types/calendar/book-a-time";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { Card } from "@hiyllo/ux/surface";
import { faEmptySet, faPlus } from "@fortawesome/pro-light-svg-icons";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { LookupView } from "../../continuity/people/components/lookup-view";
import { type UserProfileSlimType } from "../../../types/continuity/user-profile";
import moment from "moment";
import { CalendarOutlineInput } from "../views/event-details/components";
import { useSelf } from "@hiyllo/omni-continuity";
import { type TimeWithTimezone } from "../../../types/calendar/base";

import * as CreateBookATimeScheduleBP from "../../../blueprints/calendar/book-a-time/create-book-a-time-schedule";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Select } from "@hiyllo/ux/select";
import { InfoCard } from "../../../ux/info-card";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { HeaderRow, Typography } from "@hiyllo/ux/typography";
import { Pill } from "../../tasks/components/pill";
import { Button } from "@hiyllo/ux/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccessControlView } from "../../continuity/access/access-control-view";
import { ContinuityAccessType } from "../../../types/continuity/access";
import { TimeInput } from "@hiyllo/ux/time-input";

const PageContainer = styled("div", { padding: 20, height: "calc(100% - 80px)" });

const SpaceBetweenRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const Header = styled("div", { fontSize: 28 });

const NoSchedulesCardInner = styled("div", {
  padding: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 10,
});

const Label = styled("div", {
  fontSize: 16,
  marginBottom: 7.5,
});

const Spacer = styled("div", { height: 25 });

interface RangeDayRefType {
  enabled: boolean;
  start: string;
  end: string;
}

const RangeDay = React.forwardRef<RangeDayRefType, { day: string }>(
  function RangeDay(props: { day: string }, ref): JSX.Element {
    const [enabled, setEnabled] = React.useState<boolean>(false);
    const [start, setStart] = React.useState<string>("09:00");
    const [end, setEnd] = React.useState<string>("17:00");
    const [allDay, setAllDay] = React.useState<boolean>(false);

    useImperativeHandle(
      ref,
      () => ({
        enabled,
        start: allDay ? "00:00" : start,
        end: allDay ? "23:59" : end,
      }),
      [allDay, enabled, end, start],
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          height: 32,
        }}
      >
        <label
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <input
            type="checkbox"
            checked={enabled}
            onChange={(evt) => setEnabled(evt.target.checked)}
          />
          <div
            style={{
              width: 100,
              overflow: "hidden",
            }}
          >
            {moment(props.day, "ddd").format("dddd")}
          </div>
        </label>
        {enabled ? (
          <>
            <CheckboxInput
              value={allDay}
              onChange={setAllDay}
              label="All Day"
            />
            {!allDay ? (
              <>
                <TimeInput
                  value={start}
                  onChangeValue={setStart}
                />
                to
                <TimeInput
                  value={end}
                  onChangeValue={setEnd}
                />
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  },
);

function extractTimeWithTimezone(hhmm: string): TimeWithTimezone {
  const m = moment(hhmm, "HH:mm");

  return {
    hours: m.hours(),
    minutes: m.minutes(),
    timezone: moment.tz.guess(),
  };
}

const CreateSchedule = React.memo(function CreateSchedule(props: {
  retrieveSchedulesQuery: UseMoopsyQueryRetValAny;
  onClose: () => void;
}): JSX.Element {
  const self = useSelf();
  const nameInputControl = useInputControl({});
  const [members, setMembers] = React.useState<ContinuityAccessType | null>({ users: [self.userId], teams: [] });
  const [slotDuration, setSlotDuration] =
    React.useState<BookATimeSlotDuration>(45);
  const createScheduleMutation =
    seamlessClient.useMutation<CreateBookATimeScheduleBP.Plug>(
      CreateBookATimeScheduleBP,
    );
  const monRangeRef = React.useRef<RangeDayRefType>(null);
  const tueRangeRef = React.useRef<RangeDayRefType>(null);
  const wedRangeRef = React.useRef<RangeDayRefType>(null);
  const thuRangeRef = React.useRef<RangeDayRefType>(null);
  const friRangeRef = React.useRef<RangeDayRefType>(null);
  const satRangeRef = React.useRef<RangeDayRefType>(null);
  const sunRangeRef = React.useRef<RangeDayRefType>(null);
  const navigate = useNavigate();

  const create = React.useCallback(() => {
    const range: BookATimeRange[] = [];

    if (monRangeRef.current?.enabled === true) {
      console.log(
        monRangeRef.current.start,
        extractTimeWithTimezone(monRangeRef.current.start),
      );
      range.push({
        day: DayString.mon,
        from: extractTimeWithTimezone(monRangeRef.current.start),
        to: extractTimeWithTimezone(monRangeRef.current.end),
      });
    }

    if (tueRangeRef.current?.enabled === true) {
      range.push({
        day: DayString.tue,
        from: extractTimeWithTimezone(tueRangeRef.current.start),
        to: extractTimeWithTimezone(tueRangeRef.current.end),
      });
    }

    if (wedRangeRef.current?.enabled === true) {
      range.push({
        day: DayString.wed,
        from: extractTimeWithTimezone(wedRangeRef.current.start),
        to: extractTimeWithTimezone(wedRangeRef.current.end),
      });
    }

    if (thuRangeRef.current?.enabled === true) {
      range.push({
        day: DayString.thu,
        from: extractTimeWithTimezone(thuRangeRef.current.start),
        to: extractTimeWithTimezone(thuRangeRef.current.end),
      });
    }

    if (friRangeRef.current?.enabled === true) {
      range.push({
        day: DayString.fri,
        from: extractTimeWithTimezone(friRangeRef.current.start),
        to: extractTimeWithTimezone(friRangeRef.current.end),
      });
    }

    if (satRangeRef.current?.enabled === true) {
      range.push({
        day: DayString.sat,
        from: extractTimeWithTimezone(satRangeRef.current.start),
        to: extractTimeWithTimezone(satRangeRef.current.end),
      });
    }

    if (sunRangeRef.current?.enabled === true) {
      range.push({
        day: DayString.sun,
        from: extractTimeWithTimezone(sunRangeRef.current.start),
        to: extractTimeWithTimezone(sunRangeRef.current.end),
      });
    }

    if (range.length < 1) {
      return alert("Setup at least one availability range");
    }

    void createScheduleMutation
      .call({
        schedule: {
          name: nameInputControl.value,
          members: [...new Set([self.userId, ...(members?.users ?? [])])].map(
            (userId) => ({
              userId,
              optional: false,
            }),
          ),
          slotDuration,
          range,
        },
      })
      .then(({ uuid }) => {
        navigate({
          feature: Features.calendar,
          params: {
            view: "book-a-time",
            scheduleUUID: uuid,
          },
        });
      });
  }, [createScheduleMutation, members, nameInputControl.value, navigate, self.userId, slotDuration]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 20,
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          width: 0,
          flexGrow: 1,
        }}
      >
        <SpaceBetweenRow>
          <Typography.SubHeader>
            Create Scheduling Link
          </Typography.SubHeader>
          <CircleButton icon={faTimes} onClick={props.onClose} size={30} />
        </SpaceBetweenRow>

        <Label>Schedule Name</Label>
        <Input {...nameInputControl.inputProps} />

        <Spacer />

        <Typography.Label>Members</Typography.Label>
        <div style={{ width: "min(300px, 100% - 20px)" }}>
          <AccessControlView
            value={members}
            onChangeValue={setMembers}
            only="users"
          />
        </div>

        <Spacer />

        <Label>Duration</Label>
        <div style={{ width: "min(300px, 100% - 20px)" }}>
          <Select
            options={[
              {
                label: "30 Minutes",
                value: 30,
              },
              {
                label: "45 Minutes",
                value: 45,
              },
              {
                label: "1 Hour",
                value: 60,
              },
            ]}
            value={slotDuration}
            onChangeValue={(v) => setSlotDuration(v as BookATimeSlotDuration)}
            fullWidth
          />
        </div>

        <Spacer />

        <Label>Availability Range</Label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <RangeDay day="mon" ref={monRangeRef} />
          <RangeDay day="tue" ref={tueRangeRef} />
          <RangeDay day="wed" ref={wedRangeRef} />
          <RangeDay day="thu" ref={thuRangeRef} />
          <RangeDay day="fri" ref={friRangeRef} />
          <RangeDay day="sat" ref={satRangeRef} />
          <RangeDay day="sun" ref={sunRangeRef} />
        </div>

        <Spacer />

        <Typography.HeaderRow>
          <Button
            label="Create"
            onClick={create}
            isLoading={createScheduleMutation.isLoading}
          />
        </Typography.HeaderRow>
      </div>
      <InfoCard
        label="What is Book a Time?"
        title="Book a Time"
        description={[
          "Book a Time links allow anyone to book a time with you and others on your Hiyllo Work calendar. Book a Time links will automatically display times when every member you select is available, taking into account other events and busy blocks.",
        ]}
      />
    </div>
  );
});

export const ManageSchedulesInner = React.memo(
  function ManageSchedulesInner(props: {
    schedules: BookATimeSchedule[];
    retrieveSchedulesQuery: UseMoopsyQueryRetValAny;
  }): JSX.Element {
    const nav = useNavigate();
    const [isShowingCreate, setIsShowingCreate] = React.useState(false);

    if (isShowingCreate) {
      return (
        <CreateSchedule
          retrieveSchedulesQuery={props.retrieveSchedulesQuery}
          onClose={() => setIsShowingCreate(false)}
        />
      );
    }

    return (
      <div style={{ height: "100%" }}>
        <SpaceBetweenRow>
          <Header style={{ fontSize: 25 }}>
            <HeaderRow>
              Scheduling Links <Pill>Beta</Pill>
            </HeaderRow>
          </Header>
          <Button
            label={
              <>
                <FontAwesomeIcon icon={faPlus} />
                New Scheduling Link
              </>
            }
            onClick={() => setIsShowingCreate(true)}
          />
        </SpaceBetweenRow>
        {props.schedules.length === 0 ? (
          <EmptySplash icon={faEmptySet}
            label="No Schedule Links Yet"
            hint="Schedule Links allow you to share a simple link that lets external people schedule meetings on your calendar while dynamically showing only the times you're available."
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {props.schedules.map((schedule) => (
              <Card
                $color="background3"
                key={schedule.uuid}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  nav({
                    feature: Features.calendar,
                    params: {
                      view: "book-a-time",
                      scheduleUUID: schedule.uuid,
                    },
                  })
                }
              >
                {schedule.name}
              </Card>
            ))}
          </div>
        )}
      </div>
    );
  },
);

export const ManageSchedules = React.memo(
  function ManageSchedules(): JSX.Element {
    const retrieveSchedulesQuery =
      seamlessClient.useQuery<RetrieveBookATimeSchedulesBP.Plug>(
        RetrieveBookATimeSchedulesBP,
        null,
      );

    return (
      <PageContainer>
        {retrieveSchedulesQuery.isError ? (
          <div>Error</div>
        ) : retrieveSchedulesQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <ManageSchedulesInner
            schedules={retrieveSchedulesQuery.data.schedules}
            retrieveSchedulesQuery={retrieveSchedulesQuery}
          />
        )}
      </PageContainer>
    );
  },
);
