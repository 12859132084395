import React, { useContext } from "react";
import { Button } from "@hiyllo/ux/button";
import { useUploadFile } from "@hiyllo/omni-user-files";

import { FormIsLoadingContext } from "./form";
import { FormSubmitContext } from "./contexts";
import { type Theme, useThemeStyling } from "../themings";
import { cropImageToSquareAndDownsize } from "../../web-utils/image-tools";

interface PropsType {
  type: "email" | "text" | "password" | "file" | "tel" | "date" | "number";
  inputRef?: React.Ref<HTMLInputElement>;
  label?: string;
  placeholder?: string;
  fontSize?: number;
  fullWidth?: boolean;
  defaultValue?: string;
  style?: any;
  containerStyle?: any;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onInput?: React.KeyboardEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  value?: string;
  id?: string;
  themeStylingKey?: keyof Theme;
  disabled?: boolean;
  autoComplete?: string;
  noMargin?: boolean;
}

export const Input = (props: PropsType): JSX.Element => {
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const isDisabled = useContext(FormIsLoadingContext);

  return (
    <div
      style={{
        paddingBottom: 12.5,
        width:
          props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
        ...props.containerStyle,
      }}
    >
      {props.label ? (
        <div
          style={{
            paddingBottom: 2.5,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {props.label}
        </div>
      ) : null}
      <input
        type={props.type}
        ref={props.inputRef}
        disabled={isDisabled || props.disabled}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        id={props.id}
        onChange={props.onChange}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        autoComplete={props.autoComplete}
        style={{
          colorScheme: "dark",
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: "0.8em",
          outline: "none",
          border: "none",
          width: "calc(100% - 1.6em)",
          display: "block",
          fontSize: "inherit",
          fontFamily: '"Work Sans", sans-serif',
          ...inputStyling,
          ...props.style,
        }}
      />
    </div>
  );
};

export const CEInput = (
  props: Omit<PropsType, "inputRef" | "onChange"> & {
    inputRef: React.RefObject<HTMLDivElement>;
    multiline?: boolean;
    onChange?: React.ChangeEventHandler<HTMLDivElement>;
  },
): JSX.Element => {
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const isDisabled = useContext(FormIsLoadingContext) || props.disabled;
  const onSubmitForm = useContext(FormSubmitContext);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (!changed && props.inputRef.current != null) {
      props.inputRef.current.innerText = props.defaultValue ?? "";
    }
  }, [changed, props.defaultValue, props.inputRef]);

  const onSubmit = (): void => {
    if (onSubmitForm != null) {
      onSubmitForm();
    }
  };

  return (
    <div
      style={{
        width:
          props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
        userSelect: isDisabled && "none",
        ...props.containerStyle,
      }}
    >
      {props.label ? (
        <div
          style={{
            paddingBottom: 2.5,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {props.label}
        </div>
      ) : null}
      <div
        contentEditable={!isDisabled}
        ref={props.inputRef}
        id={props.id}
        onInput={props.onInput}
        onClick={
          isDisabled
            ? props.onClick
            : (evt) => {
              // @ts-expect-error - Div focus
              evt.target.focus();
              evt.stopPropagation();
            }
        }
        onKeyUp={(evt) => {
          setChanged(true);
          if (!evt.shiftKey && evt.key === "Enter") {
            evt.preventDefault();
            evt.stopPropagation();
            onSubmit();
          } else if (props.onChange != null) {
            props.onChange(evt as unknown as React.ChangeEvent<HTMLDivElement>);
          }
        }}
        style={{
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: "0.8em",
          outline: "none",
          border: "none",
          width: "calc(100% - 1.6em)",
          display: "block",
          fontSize: "inherit",
          fontFamily: '"Work Sans", sans-serif',
          cursor: isDisabled ? "pointer" : "text",
          ...(!props.multiline
            ? {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }
            : { whiteSpace: "pre-wrap" }),
          ...inputStyling,
          ...props.style,
        }}
      />
    </div>
  );
};

export const Textarea = (props: PropsType & { rows?: number }): JSX.Element => {
  const inputStyling = useThemeStyling<"input.standard">("input.standard");
  const isDisabled = useContext(FormIsLoadingContext);

  return (
    <div
      style={{
        paddingBottom: 12.5,
        width:
          props.fullWidth === true ? "100%" : "min(300px, calc(100% - 20px))",
        fontSize: props.fontSize ?? 14,
        fontFamily: '"Work Sans", sans-serif',
      }}
    >
      <div style={{ paddingBottom: 2.5 }}>{props.label}</div>
      <textarea
        ref={props.inputRef as React.Ref<HTMLTextAreaElement>}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={isDisabled}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        id={props.id}
        rows={props.rows}
        style={{
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: "0.8em",
          outline: "none",
          border: "none",
          width: "calc(100% - 1.6em)",
          display: "block",
          fontSize: "inherit",
          fontFamily: '"Work Sans", sans-serif',
          minHeight: "5em",
          ...inputStyling,
          ...props.style,
        }}
      />
    </div>
  );
};

export interface FileInputRefType {
  reset: () => void;
}

type FileInputV3PropsType = Omit<PropsType, "type"> & {
  cropToSquare?: boolean;
  onFsId: (fileId: string) => void;
  onImagePreviewUrlAvailable?: (imageUrl: string | null) => void;
};
export const FileInputV3 = React.memo(function FileInputV3(props: FileInputV3PropsType): JSX.Element {
  const [progress, setProgress] = React.useState<number | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const uploadFile = useUploadFile();
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const file = evt?.target?.files?.[0];
    if (file != null) {
      if (props.cropToSquare !== true) {
        uploadFile(file, { onProgress: setProgress })
          .then(({ fsId }) => {
            setProgress(1);
            props.onFsId(fsId);
            if (props.onImagePreviewUrlAvailable != null) {
              props.onImagePreviewUrlAvailable(URL.createObjectURL(file));
            }
          })
          .catch(() => {
            setProgress(null);
          });
      } else {
        void cropImageToSquareAndDownsize(file).then((croppedImageBlob) => {
          uploadFile(croppedImageBlob, { onProgress: setProgress })
            .then(({ fsId }) => {
              setProgress(1);
              props.onFsId(fsId);
              if (props.onImagePreviewUrlAvailable != null) {
                props.onImagePreviewUrlAvailable(
                  URL.createObjectURL(croppedImageBlob),
                );
              }
            })
            .catch(() => {
              setProgress(null);
            });
        });
      }
    }
  };

  return (
    <>
      <label>
        <Button
          label={props.label ?? "Click here to upload..."}
          isLoading={progress != null && progress !== 1}
          success={progress === 1}
          onClick={() => {
            setProgress(null);
            inputRef.current?.click();
          }}
          isSecondary
          autoWidth
        />
        <input
          type="file"
          ref={inputRef}
          onChange={onChange}
          style={{ display: "none" }}
        />
      </label>
    </>
  );
});
