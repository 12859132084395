import React from "react";
import * as JoinMeetingBP from "../../../blueprints/meet/join-meeting";
import * as JoinEventMeetingBP from "../../../blueprints/meet/join-event-meeting";
import { Track } from "livekit-client";
import {
  TrackToggle,
  useLocalParticipant,
  useRoomContext,
  useSpeakingParticipants,
  ParticipantContextIfNeeded,
} from "@livekit/components-react";

import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import {
  faArrowUpRightFromSquare,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { useNavigateTo, usePath } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import {
  ContinuityMeetingContext,
  useOnLeaveMeeting,
  type MeetingStateTypeDef,
} from "../../../main/meeting-provider";
import "@livekit/components-styles";
import { type TrackReferenceOrPlaceholder } from "@livekit/components-core";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { PreJoin } from "./prejoin";
import { TrackTile } from "./components/track-tile";
import { HiylloMeetUI } from "./hiyllo-meet-ui";

export const CollapsedVideoMeetingUI = React.memo(
  function VideoMeetingUI(): JSX.Element {
    const local = useLocalParticipant();
    const speakingParticipants = useSpeakingParticipants();
    const room = useRoomContext();
    const { current } = React.useContext(ContinuityMeetingContext);
    const expand = useNavigateTo({
      feature: Features.meet,
      params:
        current?.videoMeeting != null && "eventUUID" in current?.videoMeeting
          ? {
            view: "event",
            eventUUID: current.videoMeeting.eventUUID,
            password: current.videoMeeting.meetingPassword,
          }
          : { view: "ongoing" },
    });

    const participant = speakingParticipants[0] ?? local.localParticipant;
    const track: TrackReferenceOrPlaceholder = {
      participant,
      source: Track.Source.Camera,
      publication: participant.getTrack(Track.Source.Camera),
    };

    return (
      <div
        style={{
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <style>
          {
            " .lk-participant-media-video[data-lk-local-participant='true'][data-lk-source='camera'] { transform: rotateY(180deg); } "
          }
        </style>
        <div style={{ color: "white" }}>
          {track != null ? (
            <ParticipantContextIfNeeded participant={participant}>
              <TrackTile track={track} />
            </ParticipantContextIfNeeded>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            color: "white",
            background: "rgba(255, 255, 255, 0.25)",
            flexShrink: 0,
          }}
        >
          <TrackToggle source={Track.Source.Microphone} showIcon className="" />
          <TrackToggle source={Track.Source.Camera} showIcon className="" />
          <div
            style={{
              cursor: "pointer",
              padding: ".625rem 1rem",
            }}
            onClick={expand}
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </div>
          <div
            style={{
              cursor: "pointer",
              padding: ".625rem 1rem",
            }}
            onClick={() => room.disconnect()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    );
  },
);

const PreJoinContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  background: $theme.background1,
  color: $theme.foreground,
}));

const MeetingInner = React.memo(function MeetingInner(props: {
  mip: MeetingInitialPrefs;
  initialPrefs: MeetingInitialPrefs | null;
  room: string;
  jwt: string;
  meetingServerURL: string;
  label: string | null;
  started: Date;
  isRecording?: boolean;
  videoMeeting: MeetingStateTypeDef["videoMeeting"];
}): JSX.Element {
  const { connect, current } = React.useContext(ContinuityMeetingContext);
  const connectedRef = React.useRef<boolean>(false);
  const path = usePath();

  React.useEffect(() => {
    if (connectedRef.current && current?.room === props.room) return;

    connect({
      token: props.jwt,
      serverUrl: props.meetingServerURL,
      room: props.room,
      label: props.label,
      started: props.started,
      mip: props.mip,
      videoMeeting: props.videoMeeting,
      isRecording: props.isRecording ?? false,
    });

    connectedRef.current = true;
  }, [
    connect,
    current?.room,
    path,
    props.isRecording,
    props.jwt,
    props.label,
    props.meetingServerURL,
    props.mip,
    props.room,
    props.started,
    props.videoMeeting,
  ]);

  if (current == null) {
    return <div />;
  }

  return (
    <>
      <HiylloMeetUI />
    </>
  );
});

export interface MeetingInitialPrefs {
  videoEnabled: boolean;
  audioEnabled: boolean;
  videoDeviceId: string;
}

export const CallMeetingView = React.memo(function MeetingView(props: {
  isExternal?: boolean;
  onLeave?: () => void;
  meetingUUID: string;
  password: string | null;
  name?: string | null;
}): JSX.Element {
  const [initialPrefs, setInitialPrefs] =
    React.useState<MeetingInitialPrefs | null>(null);
  const joinMeetingQuery = seamlessClient.useQuery<JoinMeetingBP.Plug>(
    JoinMeetingBP,
    {
      meetingUUID: props.meetingUUID,
      password: props.password,
      name: props.name,
    },
  );
  const meetingState = React.useContext(ContinuityMeetingContext);

  const navigateHome = useNavigateTo({
    feature: Features.meet,
    params: null,
  });

  const onLeave = React.useCallback(() => {
    if (props.onLeave != null) {
      props.onLeave();
    } else {
      navigateHome();
    }
  }, [navigateHome, props]);
  useOnLeaveMeeting(onLeave);

  if (joinMeetingQuery.isError) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <div>{joinMeetingQuery.error.description}</div>
      </div>
    );
  }

  if (joinMeetingQuery.isLoading) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <LoadingSpinner />
        Connecting to meeting...
      </div>
    );
  }

  if (initialPrefs === null && meetingState.current == null) {
    return (
      <PreJoinContainer>
        <PreJoin
          onSubmit={(prefs) => {
            setInitialPrefs(prefs);
          }}
        />
      </PreJoinContainer>
    );
  }

  return (
    <MeetingInner
      mip={(initialPrefs ?? meetingState.current?.mip) as MeetingInitialPrefs}
      initialPrefs={initialPrefs}
      started={joinMeetingQuery.data.started}
      label={joinMeetingQuery.data.label}
      room={joinMeetingQuery.data.room}
      jwt={joinMeetingQuery.data.jwt}
      meetingServerURL={joinMeetingQuery.data.meetingServerURL}
      videoMeeting={joinMeetingQuery.data.videoMeeting}
    />
  );
});

export const EventMeetingView = React.memo(function MeetingView(props: {
  isExternal?: boolean;
  onLeave?: () => void;
  eventUUID: string;
  password: string;
  name?: string | null;
}): JSX.Element {
  const [initialPrefs, setInitialPrefs] =
    React.useState<MeetingInitialPrefs | null>(null);
  const joinMeetingQuery =
    seamlessClient.useQuery<JoinEventMeetingBP.Plug>(
      JoinEventMeetingBP,
      {
        eventUUID: props.eventUUID,
        password: props.password,
        name: props.name,
      },
    );
  const meetingState = React.useContext(ContinuityMeetingContext);

  const navigateHome = useNavigateTo({
    feature: Features.meet,
    params: null,
  });

  const onLeave = React.useCallback(() => {
    if (props.onLeave != null) {
      props.onLeave();
    } else {
      navigateHome();
    }
  }, [navigateHome, props]);
  useOnLeaveMeeting(onLeave);

  if (joinMeetingQuery.isError) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <div>{joinMeetingQuery.error.description}</div>
      </div>
    );
  }

  if (joinMeetingQuery.isLoading) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <LoadingSpinner />
        Connecting to meeting...
      </div>
    );
  }

  if (initialPrefs === null && meetingState.current == null) {
    return (
      <PreJoinContainer>
        <PreJoin
          onSubmit={(prefs) => {
            setInitialPrefs(prefs);
          }}
        />
      </PreJoinContainer>
    );
  }

  return (
    <MeetingInner
      mip={(initialPrefs ?? meetingState.current?.mip) as MeetingInitialPrefs}
      initialPrefs={initialPrefs}
      started={joinMeetingQuery.data.started}
      label={joinMeetingQuery.data.label}
      room={joinMeetingQuery.data.room}
      jwt={joinMeetingQuery.data.jwt}
      isRecording={joinMeetingQuery.data.isRecording}
      meetingServerURL={joinMeetingQuery.data.meetingServerURL}
      videoMeeting={{
        eventUUID: props.eventUUID,
        meetingPassword: props.password,
      }}
    />
  );
});
