import { styled } from "@hiyllo/ux/styled";

export const RSVPButton = styled<"div", { active: boolean }>(
  "div",
  ({ $theme, active }) => ({
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
    background: active ? $theme.midground : "transparent",
  }),
);

export const AlertDate = styled("div", ({ $theme }) => ({
  color: $theme.foregroundInactive,
  fontSize: 10.5,
  marginTop: 5,
}));

export const AlertCardIconContainer = styled("div", ({ $theme }) => ({
  minHeight: 50,
  width: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  position: "relative",
  background: $theme.background4
}));

export const AlertCardMainSection = styled("div", {
  userSelect: "none",
  display: "flex",
  flexDirection: "row",
  padding: 0,
  alignItems: "stretch",
  gap: 10,
});

export const AlertTitle = styled("div", {
  fontSize: 16,
  fontWeight: "bold",
  whiteSpace: "pre-wrap",
});

export const AlertCardContentContainer = styled("div", {
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 5,
});

export const AlertBody = styled("div", {
  fontSize: 14,
  whiteSpace: "pre-wrap",
});

export const AlertCloseIcon = styled("div", {
  fontSize: 12,
  padding: 10,
  paddingLeft: 0,
  cursor: "pointer",
  height: "100%",
});
