import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import { RenderElementProps, useSlateStatic } from 'slate-react';
import { Transforms } from 'slate';

import { EditorIsReadOnly } from '../contexts';
import { LANGUAGE_OPTIONS, ReadOnlyCodeBlock } from '../components/read-only-codeblock';
import { useChangeNodeData } from '../hooks/use-change-node-data';
import { OptionType, Select } from '@hiyllo/ux/select';

const Container = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  padding: 10,
  borderRadius: 5,
  color: $theme.foreground,
  fontFamily: "monospace",
  position: "relative"
}));

export const EditorCodeBlock = React.memo(function EditorCodeBlock(props: React.PropsWithChildren<RenderElementProps>): JSX.Element {
  const editor = useSlateStatic();
  const changeNodeData = useChangeNodeData();

  // @ts-expect-error ---
  const text = props.element.children[0]?.text;
  // @ts-expect-error ---
  const language: string | null = props.element.language ?? "markup";

  const isReadOnly = React.useContext(EditorIsReadOnly);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      Transforms.insertText(editor, '\n');
    }
  };

  if (isReadOnly) {
    // @ts-expect-error ---
    return <ReadOnlyCodeBlock content={text} language={language} />;
  }

  return (
    <Container {...props.attributes} onKeyDown={handleKeyDown}>
      <div>
        {props.children}
      </div>
      <div style={{ position: "absolute", bottom: 20, right: 0, width: 175 }}>
        <Select
          options={LANGUAGE_OPTIONS as any as OptionType[]}
          value={language}
          onChangeValue={(language) => {
            // @ts-expect-error ---
            changeNodeData(props.element, { language });
          }}
        />
      </div>
    </Container>
  );
});
