import {
  MoopsyClient,
  MoopsyClientAuthExtension,
  isMoopsyError,
} from "@moopsyjs/react";
import EJSON from "ejson";
import { getRootURL, isDesktopApp } from "./platform/environment/get-root-url";
import { type TokenPackageType } from "./types/auth/token-package";
import type * as AuthSpec from "./types/auth/AuthSpec";
import { Tenant } from "./platform/tenancy";
import { IS_BETA_ENV } from "./platform/xp";
import axios from "axios";
import {
  Stardate,
  StardateLogKind,
  StardateSourceEnum,
} from "@hiyllo/stardate/main";
import { Electron } from "./platform/electron";
import { logout } from "./platform/accounts/methods/logout";

export const SESSION_TOKEN_KEY = "sessionToken";
const stardate = new Stardate(
  StardateSourceEnum.frontendPackage,
  "omni",
  "seamless-client",
  Tenant,
);
export const seamlessClient = new MoopsyClient({
  socketUrl: getRootURL(),
  debug: true,
  axios,
  errorOutFn: (details, error) => {
    stardate.log({
      kind: StardateLogKind.error,
      message: "Client Error: " + details,
      data: {
        // @ts-expect-error ---
        connection: window.navigator.connection,
        platform: window.navigator.platform,
        vendor: window.navigator.vendor,
      },
      error
    });
  },
  _debugOutFn: (...args) => {
    // @ts-expect-error ---
    const _seamlessDebugLogs = window._seamlessDebugLogs ?? [];
    _seamlessDebugLogs.push(args);
    // @ts-expect-error ---
    window._seamlessDebugLogs = _seamlessDebugLogs.slice(-100);
    console.debug(...args);
    // stardate.log({
    //   kind: StardateLogKind.trace,
    //   message:
    //     "Debug log from Seamless Client: " +
    //     args
    //       .map((raw) => {
    //         try {
    //           return typeof raw === "string" ? raw : JSON.stringify(raw);
    //         } catch (e) {
    //           return "<u28>";
    //         }
    //       })
    //       .join(" | "),
    // });
  },
});

export const seamlessAuth = new MoopsyClientAuthExtension<AuthSpec.Typings>(
  seamlessClient,
  {
    autoLoginFunction: async () => {
      const savedTokenPackage = window.localStorage.getItem(SESSION_TOKEN_KEY);

      if (savedTokenPackage != null) {
        const { token: sessionToken } = EJSON.parse(
          savedTokenPackage,
        ) as TokenPackageType;

        return {
          sessionToken,
          tenant: Tenant,
        };
      }

      return null;
    },
  },
);

seamlessAuth.on.authError(
  (err) => {
    if (isMoopsyError(err)) {
      if (err.code === 404) {
        void logout();
      }
    }
  },
);

export const useCurrentUserId = (): string => {
  const authResponse = seamlessAuth.useAuthState();
  return authResponse?.userId ?? "_notloggedin";
};

export const useHasSavedLoginToken = (): boolean => {
  return !(window.localStorage.getItem(SESSION_TOKEN_KEY) == null);
};

export const onLoginTokenAvailable = async (
  tokenPackage: TokenPackageType,
): Promise<void> => {
  await seamlessAuth.login({
    sessionToken: tokenPackage.token,
    tenant: Tenant,
  });
  window.localStorage.setItem(SESSION_TOKEN_KEY, EJSON.stringify(tokenPackage));
};

const usp = new URLSearchParams(window.location.search);
if (usp.has("omnitoken")) {
  const token = usp.get("omnitoken");
  if (token != null) {
    void onLoginTokenAvailable(JSON.parse(token));
  }
}

let hiddenSince = Date.now();

document.addEventListener("visibilitychange", () => {
  if (!document.hidden) {
    if (Date.now() - hiddenSince > 10000) {
      seamlessClient.requestReconnect();
      seamlessClient.refreshAllQueries({ subtle: true });
    }
  }
  else {
    hiddenSince = Date.now();
  }
});