import React from 'react';
import { StuffElement } from '../../../types/stuff/element';
import { StuffAssetType } from '../../../types/stuff/asset';
import { StuffContainerType } from '../../../types/stuff/container';
import { UseMoopsyQueryRetValAny } from '@moopsyjs/react';
import { seamlessClient } from '../../../seamless-client';
import { StuffAccessEnum, StuffAccessType } from '../../../types/stuff/access';
import { useAlertDialog } from '@hiyllo/ux/dialogs';
import { ContinuityAccessType } from '../../../types/continuity/access';
import { MoopsyError } from '@moopsyjs/core';
import { Typography } from '@hiyllo/ux/typography';
import { Button } from '@hiyllo/ux/button';
import { RWAccessControlView } from '../../continuity/access/access-control-view';
import { useSelf } from '@hiyllo/omni-continuity';

import * as SetAssetAccessBP from "../../../blueprints/stuff/set-asset-access";
import * as SetContainerAccessBP from "../../../blueprints/stuff/set-container-access";
import { useTheme } from '@hiyllo/ux/theme';

function getElementValue(element: StuffElement): StuffAssetType | StuffContainerType {
  return element.type === "asset" ? element.asset : element.container;
}

export const AccessControlModal = React.memo(function AccessControlModal({
  element,
  elementQuery,
}: {
  element: StuffElement;
  elementQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const self = useSelf();
  const setAssetAccess =
    seamlessClient.useMutation<SetAssetAccessBP.Plug>(
      SetAssetAccessBP,
      { querySideEffects: [elementQuery] },
    );
  const setContainerAccess =
    seamlessClient.useMutation<SetContainerAccessBP.Plug>(
      SetContainerAccessBP,
      { querySideEffects: [elementQuery] },
    );

  const [access, setAccess] = React.useState<StuffAccessType>(getElementValue(element).access);
  const [saveElementAccessSuccess, setSaveElementAccessSuccess] =
    React.useState<boolean>(false);
  const showAlert = useAlertDialog();

  const save = React.useCallback(() => {

    (element.type === "asset" ? setAssetAccess : setContainerAccess)
      .call({
        uuid: getElementValue(element).uuid,
        set: {
          access,
        },
      })
      .then(() => {
        setSaveElementAccessSuccess(true);
      }).catch(err => {
        void showAlert({ title: "Error saving access", message: (err as MoopsyError).message });
      });
  }, [access, element, setAssetAccess, setContainerAccess, showAlert]);

  const setWriteAccess = React.useCallback(
    (value: ContinuityAccessType) => {
      setAccess((a) =>
        typeof a !== "string"
          ? {
            explicit: {
              [StuffAccessEnum.write]: value,
              [StuffAccessEnum.read]: a.explicit[StuffAccessEnum.read],
            },
          }
          : a,
      );
    },
    [setAccess],
  );

  const setReadAccess = React.useCallback(
    (value: ContinuityAccessType) => {
      setAccess((a) =>
        typeof a !== "string"
          ? {
            explicit: {
              [StuffAccessEnum.write]: a.explicit[StuffAccessEnum.write],
              [StuffAccessEnum.read]: value,
            },
          }
          : a,
      );
    },
    [setAccess],
  );

  const changed = JSON.stringify(getElementValue(element).access) !== JSON.stringify(access);

  React.useEffect(() => {
    if (changed) {
      setSaveElementAccessSuccess(false);
    }
  }, [changed]);

  const $theme = useTheme();

  return (
    <div style={{ color: $theme.foreground }}>
      <Typography.SubHeader>Who Can Edit</Typography.SubHeader>
      {access === "root" ? (
        <div>Root</div>
      ) : access === "inherit" ? (
        <div>
          <div style={{ marginBottom: 10 }}>
            Anyone with access to the parent folder can edit
          </div>
          <Button
            onClick={() =>
              setAccess({
                explicit: {
                  [StuffAccessEnum.write]: { users: [self.userId], teams: [] },
                  [StuffAccessEnum.read]: { users: [self.userId], teams: [] },
                },
              })
            }
            label="Change"
          />
        </div>
      ) : (
        <div style={{ width: 300 }}>
          <div style={{ height: 5 }} />
          <RWAccessControlView
            value={access.explicit[StuffAccessEnum.write]}
            onChangeValue={setWriteAccess}
          />
          <div style={{ height: 10 }} />
          <Typography.SubHeader>Who Can View</Typography.SubHeader>
          <div style={{ height: 5 }} />
          <RWAccessControlView
            value={access.explicit[StuffAccessEnum.read]}
            onChangeValue={setReadAccess}
          />
        </div>
      )}
      <div style={{ height: 10 }} />
      {changed || saveElementAccessSuccess ? (
        <Button
          isLoading={setAssetAccess.isLoading || setContainerAccess.isLoading}
          onClick={save}
          label="Save"
          success={saveElementAccessSuccess}
          successMessage="Saved!"
        />
      ) : null}
    </div>
  );
});