import React from "react";
import {
  type ListTasksSlimTaskType,
  type TaskSizingType,
} from "../../../types/tasks/task-item";
import {
  FixedSizingPillContext,
  FixedStatusPillContext,
  HighlightTaskContext,
} from "../kanban-view/task-kanban-view";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { ProjectName } from "../kanban-view/components";
import { SizingPill } from "@hiyllo/omni-tasks";
import { AssigneePill, StatusPill, DueDatePill, TaskPillsRow } from "@hiyllo/omni-tasks";
import { CondensedDetailsPill } from "../kanban-view/condensed-details-pill";
import {
  useClearCtxMenus,
} from "@hiyllo/ux/context-menu";

import { motion } from "framer-motion";
import { MouseState } from "@hiyllo/ux/button";
import { useMouseState } from "@hiyllo/ux/animation";
import { styled } from "@hiyllo/ux/styled";
import { TaskCtxMenuWrapper } from "./task-ctxmenu-wrapper";

const ListItemContainer = styled<"div", { $isFirst: boolean, $isLast: boolean, overdue?: boolean; isHighlighted?: boolean; translucent?: boolean }>("div", ({ $theme, overdue, isHighlighted, translucent, $isFirst, $isLast }) => ({
  padding: 5,
  // borderWidth: overdue ? 2.5 : 0,
  // borderColor: "#d3302f",
  // borderStyle: "solid",
  borderTopLeftRadius: $isFirst ? 5 : 0,
  borderTopRightRadius: $isFirst ? 5 : 0,
  borderBottomLeftRadius: $isLast ? 5 : 0,
  borderBottomRightRadius: $isLast ? 5 : 0,
  backgroundColor: isHighlighted === true ? $theme.midground : $theme.background3,
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  userSelect: "none",
  whiteSpace: "pre-wrap",
  color: $theme.foreground,
  opacity: translucent === true ? 0.3 : 1,
}));

export const HideTaskProjectLabelsCtx = React.createContext<boolean>(false);

export const TaskListItem = React.memo(function TaskListItem(props: {
  isDragging?: boolean;
  onClick?: () => void;
  task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType;
  showDescription?: string;
  onMoveTaskToTop?: (() => void) | null;
  onMoveTaskToBottom?: (() => void) | null;
  onChanged?: () => void;
  $isFirst: boolean;
  $isLast: boolean;
}): JSX.Element {
  const { onMouseEnter, onMouseLeave, onMouseDown, onMouseUp, mouseState } = useMouseState();

  const overdue =
    props.task.status.closed !== true &&
    (props.task.dueDate?.date.valueOf() ?? Infinity) < Date.now();
  const isHighlighted =
    React.useContext(HighlightTaskContext) === props.task.uuid;
  const fixedSizingPill = React.useContext(FixedSizingPillContext);
  const fixedStatusPill = React.useContext(FixedStatusPillContext);
  const updateTaskSizingMutation = useUpdateTaskSizing();

  const setSizing = React.useCallback(
    (sizing: TaskSizingType | null) => {
      void updateTaskSizingMutation.call({
        taskUUID: props.task.uuid,
        sizing,
      }).then(props.onChanged);;
    },
    [props.onChanged, props.task.uuid, updateTaskSizingMutation],
  );

  const clearCtxMenus = useClearCtxMenus();

  return (
    <TaskCtxMenuWrapper {...props}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <motion.div
          style={{ borderRadius: 5, boxShadow: mouseState !== MouseState.none ? `0px 0px 15px -10px rgba(0,0,0,0.5)` : "", }}
          animate={{
            scale:
              mouseState === MouseState.pressed
                ? 0.985
                : mouseState === MouseState.hovered
                  ? 1.0125
                  : 1,
          }}
          transition={{
            ease: [0.1, 0.12, 0.1, 0.95],
            duration: 0.1,
          }}
          initial={{ scale: 1 }}
        >
          <ListItemContainer
            onClick={() => {
              clearCtxMenus();
              props.onClick?.();
            }}
            overdue={overdue}
            isHighlighted={isHighlighted}
            translucent={props.task.deleted?.value === true}
            $isFirst={props.$isFirst || props.isDragging === true}
            $isLast={props.$isLast || props.isDragging === true}
          >
            {/* @ts-expect-error --- TaskPillsRow */}
            <TaskPillsRow>
              <CondensedDetailsPill task={props.task} />
              {fixedStatusPill ? (
                <StatusPill status={props.task.status} onChangeStatus={null} projectUUID={props.task.projectUUID} />
              ) : null}
              {props.task.dueDate !== null ? (
                <DueDatePill
                  dueDate={props.task.dueDate}
                  onChangeDueDate={null}
                  overdue={overdue}
                />
              ) : null}
              {props.task.assigneeUserId !== null ? (
                <AssigneePill
                  assignee={props.task.assigneeUserId}
                  onChangeAssignee={null}
                  hideLabel={true}
                />
              ) : null}
              {props.task.sizing !== null || fixedSizingPill ? (
                <SizingPill
                  sizing={props.task.sizing}
                  onChangeSizing={setSizing}
                  collapsed={true}
                />
              ) : null}
            </TaskPillsRow>
            {props.task.title}
            {props.showDescription ? (
              <div style={{ fontSize: "0.8em" }}>{props.showDescription}</div>
            ) : null}
            <ProjectName>
              {[props.task.projectName, props.task.sprintName]
                .filter((v) => v != null)
                .join(" / ")}
            </ProjectName>
          </ListItemContainer>
        </motion.div>
      </div>
    </TaskCtxMenuWrapper>
  );
});