import { HiylloElectronAPIApp } from "@hiyllo/electronapi";

export const Electron = new HiylloElectronAPIApp();

if (Electron.isElectron) {
  // @ts-expect-error polyfill
  Promise.withResolvers = () => {
    let resolve, reject;

    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    return { promise, resolve, reject };
  };
}