import { useHoverTriggeredWrappedPopOver } from "@hiyllo/ux/context-menu";
import React from "react";
import { LSTinyButton } from "../../components/ls-tiny-button";
import { Card } from "@hiyllo/ux/surface";

export const NewSprintButton = React.memo(function NewSprintButton(): JSX.Element {
  const { HoverTriggerContainer, CXMenuContainer } = useHoverTriggeredWrappedPopOver({ offset: { x: 75, y: 0 } });

  return (
    <HoverTriggerContainer>
      <LSTinyButton onClick={(evt: React.MouseEvent) => evt.stopPropagation()}>
        + New Sprint
      </LSTinyButton>
      <CXMenuContainer>
        <Card $color="background3">
          <div style={{ maxWidth: 200 }}>
            To create a new sprint, select a team from the sidebar
          </div>
        </Card>
      </CXMenuContainer>
    </HoverTriggerContainer>
  );
});