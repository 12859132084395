import { Editor, Element, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { SlateElementWithType } from "../types";

export const withCustomEnterBehavior = (editor: ReactEditor): ReactEditor => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [node] = Editor.node(editor, selection);

      const [parentNode] = Editor.parent(editor, selection);
      // @ts-expect-error ---
      const match = Element.isElement(parentNode) && parentNode.type === "codeblock";

      if (match) {
        // If we're inside a code block, insert a newline instead of breaking the block
        Transforms.insertText(editor, '\n');
        return;
      }

      // Check if the selected node is a void node
      if (Element.isElement(node) && editor.isVoid(node)) {
        console.log('>>> void node');
        // Insert a new paragraph after the void node
        Transforms.insertNodes(editor, {
          // @ts-expect-error ---
          type: 'paragraph',
          children: [{ text: '' }],
        });

        return;
      }
      if (Element.isElement(node) && ((node as SlateElementWithType).type === 'list-item') || (node as SlateElementWithType).type === 'codeblock') {
        console.log('>>> L40');

        // Insert a new paragraph after the void node
        Transforms.insertNodes(editor, {
          // @ts-expect-error ---
          type: 'paragraph',
          children: [{ text: '' }],
        });

        return;
      }
    }

    insertBreak();
  };

  return editor;
};