import React from "react";
import { styled } from "@hiyllo/ux/styled";

import { TasksView } from "./tasks-view";
import { useSprintOrder } from "../hooks/use-sprint-order";
import { useTasksInSprint } from "../sprints/hooks/use-tasks-in-sprint";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";

const Container = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

export const SprintKanbanView = React.memo(function SprintKanbanView(props: {
  sprintUUID: string;
}): JSX.Element {
  const tasks = useTasksInSprint(props.sprintUUID);
  const { order, onChangeOrder } = useSprintOrder({
    sprintUUID: props.sprintUUID,
  });

  if (order === null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <Container>
      <TasksView
        order={order}
        onChangeOrder={(order, updated) => {
          onChangeOrder(order, updated);
          if (updated != null) tasks._ingest([updated], false);
        }}
        tasks={tasks.data}
        isReady={!tasks.queryLoading}
        hideTaskProjectLabels={false}
        cacheKey={props.sprintUUID}
        disableFilters={["sprint-filter"]}
      />
    </Container>
  );
});
