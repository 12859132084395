import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Stardate,
  StardateLogKind,
  StardateSourceEnum,
} from "@hiyllo/stardate/main";
import { Typography } from "@hiyllo/ux/typography";
import React from "react";
import { Tenant } from "./tenancy";
import { EmptySplash } from "@hiyllo/ux/empty-splash";

interface StateType {
  hasError: boolean;
  errorMessage: null | string;
}

interface PropsType {
  children: React.ReactNode;
  code?: string;
  overrideFallback?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null,
    };
  }

  static getDerivedStateFromError(er: Error): StateType {
    return {
      hasError: true,
      errorMessage: er.message,
    };
  }

  componentDidCatch(er: Error, errorInfo: React.ErrorInfo): void {
    console.error(er, errorInfo);
    const stardate = new Stardate(
      StardateSourceEnum.frontendWeb,
      "omni",
      "error-boundary",
      Tenant,
    );
    if (window.location.hostname !== "localhost") {
      stardate.log({
        kind: StardateLogKind.error,
        message: "React Error boundary caught",
        data: { errorInfo },
        error: er,
      });
    }
    this.setState({ errorMessage: er.message });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.overrideFallback) return this.props.overrideFallback;

      return (
        <EmptySplash
          icon={faExclamationTriangle}
          label="This part of Hiyllo Work has crashed due to an error. Please reload the page, and contact support@hiyllo.io if this issue persists"
          hint={this.state.errorMessage}
        />
      );
    }
    return this.props.children;
  }
}
