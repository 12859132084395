import React from "react";
import {
  type ListTasksSlimTaskType,
  type TaskSizingType,
} from "../../../types/tasks/task-item";
import {
  FixedSizingPillContext,
  FixedStatusPillContext,
  HighlightTaskContext,
} from "../kanban-view/task-kanban-view";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { ProjectName } from "../kanban-view/components";
import { SizingPill } from "@hiyllo/omni-tasks";
import { AssigneePill, StatusPill, DueDatePill, TaskPillsRow } from "@hiyllo/omni-tasks";
import { CondensedDetailsPill } from "../kanban-view/condensed-details-pill";
import {
  useClearCtxMenus,
} from "@hiyllo/ux/context-menu";

import { useGetTask } from "@hiyllo/omni-tasks";
import { motion, useMotionValue } from "framer-motion";
import { MouseState } from "@hiyllo/ux/button";
import { useMouseState } from "@hiyllo/ux/animation";
import { styled } from "@hiyllo/ux/styled";
import { TaskCtxMenuWrapper } from "./task-ctxmenu-wrapper";

export const TaskCardContainer = styled<"div", { overdue?: boolean; isHighlighted?: boolean; translucent?: boolean }>("div", ({ $theme, overdue, isHighlighted, translucent }) => ({
  padding: overdue ? 8 : 10,
  borderWidth: overdue ? 2 : 0,
  borderColor: "#d3302f",
  borderStyle: "solid",
  borderRadius: 5,
  backgroundColor: isHighlighted === true ? $theme.midground : $theme.background3,
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 10,
  userSelect: "none",
  whiteSpace: "pre-wrap",
  color: $theme.foreground,
  opacity: translucent === true ? 0.3 : 1,
}));

export const HideTaskProjectLabelsCtx = React.createContext<boolean>(false);

export const TaskCard = React.memo(function TaskCard(props: {
  isDragging?: boolean;
  onClick?: () => void;
  task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType;
  showDescription?: string;
  onMoveTaskToTop?: (() => void) | null;
  onMoveTaskToBottom?: (() => void) | null;
  onChanged?: () => void;
}): JSX.Element {
  const { onMouseEnter, onMouseLeave, onMouseDown, onMouseUp, mouseState } = useMouseState();

  const overdue =
    props.task.status.closed !== true &&
    (props.task.dueDate?.date.valueOf() ?? Infinity) < Date.now();
  const isHighlighted =
    React.useContext(HighlightTaskContext) === props.task.uuid;
  const fixedSizingPill = React.useContext(FixedSizingPillContext);
  const fixedStatusPill = React.useContext(FixedStatusPillContext);
  const updateTaskSizingMutation = useUpdateTaskSizing();

  const setSizing = React.useCallback(
    (sizing: TaskSizingType | null) => {
      void updateTaskSizingMutation.call({
        taskUUID: props.task.uuid,
        sizing,
      }).then(props.onChanged);;
    },
    [props.onChanged, props.task.uuid, updateTaskSizingMutation],
  );

  const rotateX = useMotionValue(0);
  const rotateY = useMotionValue(0);
  const clearCtxMenus = useClearCtxMenus();

  return (
    <TaskCtxMenuWrapper {...props}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <motion.div
          style={{ rotateX, rotateY, borderRadius: 5, boxShadow: mouseState !== MouseState.none ? `0px 0px 15px -10px rgba(0,0,0,0.5)` : "", }}
          animate={{
            scale:
              mouseState === MouseState.pressed
                ? 0.975
                : mouseState === MouseState.hovered
                  ? 1.025
                  : 1,
          }}
          transition={{
            ease: [0.1, 0.12, 0.1, 0.95],
            duration: 0.1,
          }}
          initial={{ scale: 1 }}
          onMouseMove={(event) => {
            const rect = event.currentTarget.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            const centerX = rect.width / 2;
            const centerY = rect.height / 2;
            const deltaX = (x - centerX) / centerX;
            const deltaY = (y - centerY) / centerY;
            rotateY.set(deltaX * 5);
            rotateX.set(deltaY * 5);
          }}
          onMouseLeave={() => {
            rotateX.set(0);
            rotateY.set(0);
          }}
        >
          <TaskCardContainer
            onClick={() => {
              clearCtxMenus();
              props.onClick?.();
            }}
            overdue={overdue}
            isHighlighted={isHighlighted}
            translucent={props.task.deleted?.value === true}
          >
            {/* @ts-expect-error --- TaskPillsRow */}
            <TaskPillsRow>
              <CondensedDetailsPill task={props.task} />
              {fixedStatusPill ? (
                <StatusPill status={props.task.status} onChangeStatus={null} projectUUID={props.task.projectUUID} />
              ) : null}
              {props.task.dueDate !== null ? (
                <DueDatePill
                  dueDate={props.task.dueDate}
                  onChangeDueDate={null}
                  overdue={overdue}
                />
              ) : null}
              {props.task.assigneeUserId !== null ? (
                <AssigneePill
                  assignee={props.task.assigneeUserId}
                  onChangeAssignee={null}
                  hideLabel={true}
                />
              ) : null}
              {props.task.sizing !== null || fixedSizingPill ? (
                <SizingPill
                  sizing={props.task.sizing}
                  onChangeSizing={setSizing}
                  collapsed={true}
                />
              ) : null}
            </TaskPillsRow>
            {props.task.title}
            {props.showDescription ? (
              <div style={{ fontSize: "0.8em" }}>{props.showDescription}</div>
            ) : null}
            <ProjectName>
              {[props.task.projectName, props.task.sprintName]
                .filter((v) => v != null)
                .join(" / ")}
            </ProjectName>
          </TaskCardContainer>
        </motion.div>
      </div>
    </TaskCtxMenuWrapper>
  );
});

export const TaskCardQueryWrapper = React.memo(
  function TaskCardQueryWrapper(props: {
    taskUUID: string;
  }): JSX.Element | null {
    const taskQuery = useGetTask({ uuid: props.taskUUID });

    if (taskQuery.isLoading || taskQuery.isError) {
      return null;
    }

    return (
      <TaskCard
        task={taskQuery.data.task as Omit<ListTasksSlimTaskType, "assignee">}
      />
    );
  },
);
