/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContinuityAccessType } from "../continuity/access";
import { GeoCoords } from "../geo/coords";
import { DateYYYYMMDD, TimeWithTimezone } from "./base";
import { RepeatingConfig } from "./repeating";

export enum EventTypeEnum {
  /**
   * Standard Event
   */
  event = "event",
  /**
   * Busy/OOO Block
   */
  busy = "busy",
  /**
   * Location Selection, sets where the user will be for the purpose of travel time calculation
   */
  location = "location",
  /**
   * (Typically) auto generated event to represent travel time
   */
  travel = "travel",
}


export enum RSVPEnum {
  yes = "yes",
  no = "no",
  maybe = "maybe",
}

export type InternalInvitee = {
  type: "internal";
  userId: string;
  accessFromTeam?: string[] | null;
};
export type ExternalInvitee = {
  type: "external";
  emailAddress: string;
  name: string | null;
  timezone?: string | null;
};
export type ExternalInviteeSMS = {
  type: "externalSMS";
  phoneNumberE164: string;
  name: string | null;
};
export type TeamInvitee = { type: "team"; teamUUID: string };

export type EventGuestInviteeType =
  | InternalInvitee
  | ExternalInvitee
  | ExternalInviteeSMS
  | TeamInvitee;

export interface EventGuestType<
  I extends EventGuestInviteeType = EventGuestInviteeType
> {
  invitee: I;
  rsvp: RSVPEnum | null;
  externalActionToken: string;
  optional: boolean;
}

export interface EventTiming {
  date: DateYYYYMMDD;
  allDay?: boolean;
  start: TimeWithTimezone;
  durationInMinutes: number;
}

export interface EventTimingWithComputed extends EventTiming {
  _computed: {
    start: Date;
    end: Date;
  };
}

export interface EventLocationType {
  /**
   * The input value (address, POI, etc)
   */
  input: string;
  /**
   * The resolved coordinates, null if yet to be resolved
   */
  coords: GeoCoords | null;
}

interface HiylloMeetVideoMeetingType {
  type: "hiyllo-meet";
  meetingUUID: string;
  meetingPassword: string;
}

export type VideoMeetingType = HiylloMeetVideoMeetingType;

export type RecordedInIntegrationsAsType = Array<{
  type: "hiyllowork";
  uuid: string;
  userId: string;
}>;

export type CalendarEventDeletionReason =
  | "user-request"
  | "api"
  | "repeat-config-change"
  | "remote-event-deleted"
  | "migration"
  | "attached-event-deleted"
  | "autogenerated-replacement";

export interface EventBaseMeta {
  title: string;
  type: EventTypeEnum;
  description: string;
  appearence: null | {
    color: string;
  };
  creator: string;
  owner: string;
  guests: EventGuestType[];
  createdDate: Date;
  lastModified: Date;
  videoMeeting: VideoMeetingType | null;
  disableNotifications?: boolean;
  deleted?: null | {
    value: true;
    reason: CalendarEventDeletionReason;
    on: Date;
  };
  creatorAppId?: string;
  /**
   * The remote reference the event originated as
   */
  originRemoteReference?: string;
  remoteEventReferences?: string[] | null;
  /**
   * Human Readable string describing the source of the event
   */
  source?: string;
  /**
   * Location the event takes place at.
   * Nullish values mean take the previous location, or the home location
   */
  location?: EventLocationType | null;
  /**
   * (optional) book a time this event is associated with
   */
  associatedWithBookATime?: string;
  /**
   * (optional) event this event is attached to, for example if this event is travel
   * time this would be the event that is being travelled to. If the attachedToEvent
   * gets deleted, this event should also be deleted.
   */
  attachedToEvent?: string;

  /**
   * If nullish, any non-external guest can edit.
   */
  editAccess?: ContinuityAccessType | null;
  /**
   * A link that can be provided to allow people to spectate the event. Spectators cannot be seen or
   * heard, and their names will not be visible to other spectator.
   */
  spectatorLink?: { token: string; } | null;
}

export interface CalendarRepeatingEventEntry extends EventBaseMeta {
  from: Date;
  to: Date;
  repeats: RepeatingConfig;
  timing: EventTiming;
  uuid: string;
  skip?: Date[];
  version?: number;
}

type CalendarEventRecordingActiveType = {
  started: Date;
  startedBy: string;
};

export enum TranscriptionStatusEnum {
  running = "running",
  completed = "completed",
}

export interface CalendarEvent extends EventBaseMeta {
  timing: EventTimingWithComputed;

  repeat: {
    /**
     * UUID shared across events that are tied to each other.
     * Repeating is computed in Omni, but in the event of an edit to an event
     * that is not a "edit all", we'll create a
     */
    uuid: string | null;

    /**
     * Reproducible "hash-like" key that represents this unique event.
     * repeatKey = event.repeatUUID + event.timing.date
     */
    key: string | null;
  } | null;

  uuid: string;
  /**
   * Notifications that have been sent, number is minutes before event
   */
  notificationsSent?: number[];

  recordingStatus?:
  | "not-recorded"
  | "recording-in-progress"
  | "recording-available";
  recordingActive?: CalendarEventRecordingActiveType | null;
  transcriptionStatus?: TranscriptionStatusEnum | null;
  transcriptUUID?: string | null;
  limitedAccess?: boolean;
  spectators?: string[];
}
